import { get, post } from "../api_helper";

const Get_PushNotifications = body => {
  let url = "api/Get_PushNotifications";

  return get(url, body);
};

const Get_Companies_dropdown = body => {
  let url = "Campaigns/Get_Companies_dropdown";

  return get(url, body);
};

const Save_PushNotification = body => {
  let url = "api/Save_PushNotification";
  return post(url, body);
};

const Edit_PushNotification = body => {
  let url = "api/Edit_PushNotification";
  return post(url, body);
};

const Delete_PushNotification = body => {
  let url = "api/Delete_PushNotification";
  return post(url, body);
};
const anchorTable = [
  {
    Header: "S.No..",
    accessor: "indexing",
    hidden: false,
    width: 50,
  },
  {
    Header: "Date",
    accessor: "date",
    hidden: false,
  },
  {
    Header: "Message",
    accessor: "message",
    hidden: false,
  },
  {
    Header: "IsActive",
    accessor: "IsActive",
    hidden: false,
    width: 100,
  },
  {
    Header: "Created By",
    accessor: "CreatedBy",
    hidden: false,
    width: 100,
  },
  {
    Header: "Created On",
    accessor: "CreatedOn",
    hidden: false,
    width: 120,
  },
  {
    Header: "Modified On",
    accessor: "ModifiedOn",
    hidden: true,
    width: 120,
  },
  {
    Header: "Action",
    accessor: "Action",
    edit: false,
    delete: true,
    view: false,
    add: true,
    hidden: false,
    width: 100,
  },
];

export default {
  Get_PushNotifications,
  Get_Companies_dropdown,
  Save_PushNotification,
  Edit_PushNotification,
  Delete_PushNotification,
  anchorTable,
};
