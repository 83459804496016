import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { useDispatch } from "react-redux";
import {
  setPopupMessageValues,
  login_message_clear,
  openLeaveApprovalForm,
  setDashboardFormValues,
  setDashboardPopupValues,
  setLeaveApprovalStatusValues,
  setPopMessage,
} from "store/actions";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

const DialogPopup = props => {
  const { message, branch } = props;
  const dispatch = useDispatch();

  const onCloseClick = () => {
    if (branch === "Login") {
      dispatch(login_message_clear(null));
    } else if (branch === "Dashboard") {
      dispatch(setDashboardFormValues(null));
      dispatch(openLeaveApprovalForm(false));
      dispatch(setLeaveApprovalStatusValues(null));
      dispatch(setDashboardPopupValues(null));
    } else if (branch === "Profile") {
      dispatch(setPopMessage(null));
    } else {
      dispatch(setPopupMessageValues(null, branch));
    }
  };
  return (
    // <Modal size="sm" isOpen={message} toggle={onCloseClick} centered={true}>
    //   <div className="modal-content">
    //     <ModalBody className="px-4 py-5 text-center">
    //       {/* <button
    //         type="button"
    //         onClick={onDeleteClick}
    //         className="btn-close position-absolute end-0 top-0 m-3"
    //       ></button> */}
    //       {/* <div className="avatar-sm mb-6 mx-auto">
    //         <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
    //           <i className="mdi mdi-trash-can-outline"></i>
    //         </div>
    //       </div> */}
    //       <p className="text-muted font-size-16 mb-8">{message}</p>

    //       <div className="hstack gap-2 justify-content-center mb-0">
    //         {/* <button type="button" className="btn btn-danger" onClick={onDeleteClick}>Delete Now</button> */}
    //         <button
    //           type="button"
    //           className="btn btn-secondary"
    //           onClick={onCloseClick}
    //         >
    //           Close
    //         </button>
    //       </div>
    //     </ModalBody>
    //   </div>
    // </Modal>
    <div>
      <Dialog
        open={message == null ? false : true}
        onClose={onCloseClick}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          style={{
            color: "black",
            fontWeight: "bold",
            fontSize: 16,
            textDecoration: "underline solid black 2px",
          }}
        >
          <span style={{ color: "black" }}>Confirmation</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ minWidth: 300, maxWidth: 400, color: "black" }}
          >
            {/* {message} */}
            {message === null ? "" : message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <NavLink to={path} style={{ textDecoration: "none" }}>
           
          </NavLink> */}
          <Button
            onClick={onCloseClick}
            style={{
              color: "black",
              fontWeight: "bold",
              backgroundColor: "#3f51b5",
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DialogPopup.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
};

export default DialogPopup;
