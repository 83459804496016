import { get, post } from "../api_helper";

const Get_Users_By_CampaignID = id => {
  let url = "api/Get_Tickets_By_CampaignId=" + id;
  return get(url);
};

const Declare_Winner = body => {
  let url = "api/Declare_Winner";
  return post(url, body);
};

const anchorTable = [
  {
    Header: "S.No..",
    accessor: "indexing",
    hidden: false,
    width: 50,
  },
  {
    Header: "Customer Name",
    accessor: "customerName",
    hidden: false,
  },
  {
    Header: "Total Tickets",
    accessor: "tickets",
    hidden: false,
  },

  {
    Header: "Action",
    accessor: "declareWinner",
    hidden: false,
    width: 120,
  },
  {
    Header: "Action",
    accessor: "Action",
    edit: false,
    delete: false,
    view: false,
    add: true,
    hidden: true,
    width: 100,
  },
];

export default {
  Get_Users_By_CampaignID,
  Declare_Winner,
  anchorTable,
};
