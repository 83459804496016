import {
  API_SUCCESS,
  API_FAIL,
  GET_CHARTS_DATA,
  OPEN_LEAVE_APPROVAL_FORM,
  SET_FORM_VALUES,
  SET_DASHBOARD_POPUP_VALUES,
  SET_DASHBOARD_IS_COPIED,
  SET_LEAVE_APPROVAL_STATUS_VALUES,
  SET_ATTENDANCE_ID,
} from "./actionTypes";

const INIT_STATE = {
  chartsData: [],
  formOpen: false,
  formValues: null,
  popupMsgValues: null,
  leaveApprovalStatus: null,
  isCopied: false,
  Attendance_Id: null,
};

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_SUCCESS:
      switch (action.payload.actionType) {
        case GET_CHARTS_DATA:
          return {
            ...state,
            chartsData: action.payload.data,
          };
        default:
          return state;
      }
    case API_FAIL:
      switch (action.payload.actionType) {
        case GET_CHARTS_DATA:
          return {
            ...state,
            chartsDataError: action.payload.error,
          };

        default:
          return state;
      }
    case OPEN_LEAVE_APPROVAL_FORM:
      return {
        ...state,
        formOpen: action.payload,
      };
    case SET_FORM_VALUES:
      return {
        ...state,
        formValues: action.payload,
      };
    case SET_DASHBOARD_POPUP_VALUES:
      return {
        ...state,
        popupMsgValues: action.payload,
      };
    case SET_DASHBOARD_IS_COPIED:
      return {
        ...state,
        isCopied: action.payload,
      };
    case SET_LEAVE_APPROVAL_STATUS_VALUES:
      return {
        ...state,
        leaveApprovalStatus: action.payload,
      };
    case SET_ATTENDANCE_ID:
      return {
        ...state,
        Attendance_Id: action.payload,
      };
    default:
      return state;
  }
};

export default Dashboard;
