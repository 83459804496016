import { get, post } from "../api_helper";

const Get_Winners = body => {
  let url = "api/Get_Winners";

  return get(url, body);
};

const Get_Companies_dropdown = body => {
  let url = "Campaigns/Get_Companies_dropdown";

  return get(url, body);
};

const Save_Winner = body => {
  let url = "api/Save_Winner";
  return post(url, body);
};

const Edit_Winner = body => {
  let url = "api/Edit_Winner";
  return post(url, body);
};

const Delete_Winner = body => {
  let url = "api/Delete_Winner";
  return post(url, body);
};
const anchorTable = [
  {
    Header: "S.No..",
    accessor: "indexing",
    hidden: false,
    width: 50,
  },
  {
    Header: "Winner Name",
    accessor: "name",
    hidden: false,
  },
  {
    Header: "Campaign",
    accessor: "campaignName",
    hidden: false,
  },
  {
    Header: "Image",
    accessor: "imageURL",
    hidden: true,
  },
  {
    Header: "Announced On",
    accessor: "announcedOn",
    hidden: false,
  },
  {
    Header: "IsActive",
    accessor: "IsActive",
    hidden: false,
    width: 100,
  },
  {
    Header: "Created By",
    accessor: "CreatedBy",
    hidden: false,
    width: 100,
  },
  {
    Header: "Created On",
    accessor: "CreatedOn",
    hidden: false,
    width: 120,
  },
  {
    Header: "Modified On",
    accessor: "ModifiedOn",
    hidden: false,
    width: 120,
  },
  {
    Header: "Action",
    accessor: "Action",
    edit: false,
    delete: true,
    view: false,
    add: false,
    hidden: false,
    width: 100,
  },
];

export default {
  Get_Winners,
  Get_Companies_dropdown,
  Save_Winner,
  Edit_Winner,
  Delete_Winner,
  anchorTable,
};
