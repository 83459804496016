import React, { useEffect, useRef, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "components/Common/withRouter";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = props => {
  const ref = useRef();

  let Login = useSelector(state => state.Login);
  const theme = useSelector(state => state.Layout.layoutModeType);

  const activateParentDropdown = useCallback(item => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>

            <li>
              <Link
                to="/dashboard"
                style={{
                  color: theme == "light" ? "black" : "white",
                }}
              >
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>
            <li>
              <Link
                to="/home_banner"
                style={{
                  color: theme == "light" ? "black" : "white",
                }}
              >
                <i className="bx bx-list-ul"></i>
                <span>{props.t("HomeBanner")}</span>
              </Link>
            </li>
            <li>
              <Link
                to="/users"
                style={{
                  color: theme == "light" ? "black" : "white",
                }}
              >
                <i className="bx bx-list-ul"></i>
                <span>{props.t("Users")}</span>
              </Link>
            </li>
            <li>
              <Link
                to="/campaigns"
                style={{
                  color: theme == "light" ? "black" : "white",
                }}
              >
                <i className="bx bx-list-ul"></i>
                <span>{props.t("Campagins")}</span>
              </Link>
            </li>
            <li>
              <Link
                to="/campagi_categories"
                style={{
                  color: theme == "light" ? "black" : "white",
                }}
              >
                <i className="bx bx-list-ul"></i>
                <span>{props.t("Campagin Categories")}</span>
              </Link>
            </li>
            <li>
              <Link
                to="/products"
                style={{
                  color: theme == "light" ? "black" : "white",
                }}
              >
                <i className="bx bx-list-ul"></i>
                <span>{props.t("Products")}</span>
              </Link>
            </li>
            <li>
              <Link
                to="/prize"
                style={{
                  color: theme == "light" ? "black" : "white",
                }}
              >
                <i className="bx bx-list-ul"></i>
                <span>{props.t("Prize")}</span>
              </Link>
            </li>
            {/* <li>
              <Link
                to="/draws"
                style={{
                  color: theme == "light" ? "black" : "white",
                }}
              >
                <i className="bx bx-list-ul"></i>
                <span>{props.t("Draws")}</span>
              </Link>
            </li> */}
            <li>
              <Link
                to="/tickets"
                style={{
                  color: theme == "light" ? "black" : "white",
                }}
              >
                <i className="bx bx-list-ul"></i>
                <span>{props.t("Tickets")}</span>
              </Link>
            </li>
            <li>
              <Link
                to="/winners"
                style={{
                  color: theme == "light" ? "black" : "white",
                }}
              >
                <i className="bx bx-list-ul"></i>
                <span>{props.t("Declared Winners")}</span>
              </Link>
            </li>
            {/* <li>
              <Link
                to="/coupons"
                style={{
                  color: theme == "light" ? "black" : "white",
                }}
              >
                <i className="bx bx-list-ul"></i>
                <span>{props.t("Coupons")}</span>
              </Link>
            </li> */}
            <li>
              <Link
                to="/push_notifications"
                style={{
                  color: theme == "light" ? "black" : "white",
                }}
              >
                <i className="bx bx-list-ul"></i>
                <span>{props.t("Push Notifications")}</span>
              </Link>
            </li>
            {/* <li>
              <Link
                to="/multi_currency"
                style={{
                  color: theme == "light" ? "black" : "white",
                }}
              >
                <i className="bx bx-list-ul"></i>
                <span>{props.t("Multi Currency")}</span>
              </Link>
            </li> */}
            <li>
              <Link
                to="/shipping_charges"
                style={{
                  color: theme == "light" ? "black" : "white",
                }}
              >
                <i className="bx bx-list-ul"></i>
                <span>{props.t("Shipping Charges")}</span>
              </Link>
            </li>
            <li>
              <Link
                to="/orders"
                style={{
                  color: theme == "light" ? "black" : "white",
                }}
              >
                <i className="bx bx-list-ul"></i>
                <span>{props.t("Orders")}</span>
              </Link>
            </li>
            <li>
              <Link
                to="/terms-and-conditions"
                style={{
                  color: theme == "light" ? "black" : "white",
                }}
              >
                <i className="bx bx-list-ul"></i>
                <span>{props.t("Terms & Conditions")}</span>
              </Link>
            </li>

            {/* //Mobile App */}
            {/* <li>
              <Link
                to="/#"
                className="has-arrow"
                style={{
                  color: theme == "light" ? "black" : "white",
                }}
              >
                <i className="bx bx-list-ul"></i>
                {props.t("Mobile App")}
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link
                    to="/mobile_app/packages"
                    style={{
                      color: theme == "light" ? "black" : "white",
                    }}
                  >
                    {props.t("Packages")}
                  </Link>
                </li>
              </ul>
            </li> */}

            {/* {Login.Menu !== null && Login.Menu !== undefined
              ? Login.Menu.map((menu, index1) => {
                  return (
                    <li key={index1}>
                      <Link
                        to="/#"
                        className="has-arrow"
                        style={{
                          color: theme == "light" ? "black" : "white",
                        }}
                      >
                        <i className={menu.icon}></i>
                        <span
                        // style={{
                        //   color: theme == "light" ? "black" : "white",
                        // }}
                        >
                          {props.t(menu.name)}
                        </span>
                      </Link>
                      <ul
                        className="sub-menu"
                        // style={{
                        //   backgroundColor:
                        //     theme == "light" ? "white" : "#1D1D2B",
                        // }}
                      >
                        {menu.child.map((submenu, index2) => {
                          return (
                            <li key={index2}>
                              <Link
                                to={submenu.link}
                                style={{
                                  color: theme == "light" ? "black" : "white",
                                }}
                              >
                                {props.t(submenu.name)}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                })
              : ""} */}

            {/* <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-list-ul"></i>
                <span>{props.t("Master Data")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/master/department">{props.t("Department")}</Link>
                </li>
                <li>
                  <Link to="/master/designation">{props.t("Designation")}</Link>
                </li>
              </ul>
            </li> */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
