import * as constants from "./crudTbFrmConstant";

export const setData = (newData, branch) => ({
  branch,
  type: `${branch}/${constants.SET_DATA}`,
  payload: newData,
});

export const addNewItem = (newData, branch) => ({
  branch,
  type: `${branch}/${constants.ADD_NEW_ITEM}`,
  payload: newData,
});

export const updateItem = (newData, branch) => ({
  branch,
  type: `${branch}/${constants.UPDATE_ITEM}`,
  payload: newData,
});

export const removeItem = (item, branch) => ({
  branch,
  type: `${branch}/${constants.REMOVE_ITEM}`,
  payload: item,
});

export const toggleForm = (newData, branch) => ({
  branch,
  type: `${branch}/${constants.TOGGLE_FORM}`,
  payload: newData,
});

export const setFormValues = (formData, branch) => ({
  branch,
  type: `${branch}/${constants.SET_FORM_VALUES}`,
  payload: formData,
});

export const setPopupMessageValues = (message, branch) => ({
  branch,
  type: `${branch}/${constants.SET_POP_MESSAGE_VALUES}`,
  payload: message,
});

export const setFiles = (data, branch) => ({
  branch,
  type: `${branch}/${constants.ADD_FILE}`,
  payload: data,
});
