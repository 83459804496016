import { get, post } from "../api_helper";

const Get_Campaigns = body => {
  let url = "api/Get_Campaigns";

  return get(url, body);
};

const Get_Campaigns_Dropdown = body => {
  let url = "api/Get_Campaigns_Dropdown";

  return get(url, body);
};

const Get_OutOfStock_Campaigns_Dropdown = () => {
  let url = "api/Get_OutOfStock_Campaigns_Dropdown";

  return get(url);
};

const Save_Campaigns = body => {
  let url = "api/Save_Campaign";
  return post(url, body);
};

const Edit_Campaigns = body => {
  let url = "api/Edit_Campaign";
  return post(url, body);
};

const Delete_Campaigns = body => {
  let url = "api/Delete_Campaign";
  return post(url, body);
};
const anchorTable = [
  {
    Header: "S.No..",
    accessor: "indexing",
    hidden: false,
    width: 50,
  },
  {
    Header: "Name",
    accessor: "name",
    hidden: false,
  },
  {
    Header: "Description",
    accessor: "description",
    hidden: false,
  },
  {
    Header: "category",
    accessor: "campaignCategoryName",
    hidden: false,
  },
  {
    Header: "Prize",
    accessor: "prizeName",
    hidden: false,
  },
  {
    Header: "Product",
    accessor: "productName",
    hidden: false,
  },
  {
    Header: "Draw Date",
    accessor: "drawDateTime",
    hidden: false,
  },
  {
    Header: "Image",
    accessor: "imageURL",
    hidden: false,
  },
  {
    Header: "IsActive",
    accessor: "IsActive",
    hidden: true,
    width: 100,
  },
  {
    Header: "Created By",
    accessor: "CreatedBy",
    hidden: true,
    width: 100,
  },
  {
    Header: "Created On",
    accessor: "CreatedOn",
    hidden: false,
    width: 120,
  },
  {
    Header: "Modified On",
    accessor: "ModifiedOn",
    hidden: false,
    width: 120,
  },
  {
    Header: "Action",
    accessor: "Action",
    edit: true,
    delete: true,
    view: false,
    add: true,
    hidden: false,
    width: 100,
  },
];

export default {
  Get_Campaigns,
  Get_Campaigns_Dropdown,
  Get_OutOfStock_Campaigns_Dropdown,
  Save_Campaigns,
  Edit_Campaigns,
  Delete_Campaigns,
  anchorTable,
};
