import { del, get, post, put } from "../api_helper";

// const Received_Notification = (Ticket, User) => {
//   let url = `api/Broadcast/Received_Notification?Ticket=${Ticket}&User=${User}`;
//   let params = {
//     method: "POST",
//     Content_Type: "application/json",
//   };

//   //  return new apicalls().call_api(params, url);
//   return apicalls.get(params, url);
// };

// const Read_Notification = (Ticket, User) => {
//   let url = `api/Broadcast/Read_Notification?Ticket=${Ticket}&User=${User}`;
//   let params = {
//     method: "POST",
//     Content_Type: "application/json",
//   };

//   //  return new apicalls().call_api(params, url);
//   return apicalls.get(params, url);
// };

const Received_Notification = (Ticket, User) => {
  let url = `api/Broadcast/Received_Notification?Ticket=${Ticket}&User=${User}`;

  return get(url);
};

const Read_Notification = (Ticket, User) => {
  let url = `api/Broadcast/Read_Notification?Ticket=${Ticket}&User=${User}`;

  return get(url);
};

export default {
  Received_Notification,
  Read_Notification,
};
