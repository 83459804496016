import { get, post } from "../api_helper";

const Get_Coupons = body => {
  let url = "api/Get_Coupons";

  return get(url, body);
};

const Get_Companies_dropdown = body => {
  let url = "Campaigns/Get_Companies_dropdown";

  return get(url, body);
};

const Save_Coupon = body => {
  let url = "api/Save_Coupon";
  return post(url, body);
};

const Edit_Coupon = body => {
  let url = "api/Edit_Coupon";
  return post(url, body);
};

const Delete_Coupon = body => {
  let url = "api/Delete_Coupon";
  return post(url, body);
};
const anchorTable = [
  {
    Header: "S.No..",
    accessor: "indexing",
    hidden: false,
    width: 50,
  },
  {
    Header: "Name",
    accessor: "name",
    hidden: false,
  },
  {
    Header: "Code",
    accessor: "code",
    hidden: false,
  },
  {
    Header: "Discount Type",
    accessor: "discountType",
    hidden: false,
  },
  {
    Header: "Value",
    accessor: "value",
    hidden: false,
  },
  {
    Header: "IsActive",
    accessor: "IsActive",
    hidden: true,
    width: 100,
  },
  {
    Header: "Created By",
    accessor: "CreatedBy",
    hidden: true,
    width: 100,
  },
  {
    Header: "Created On",
    accessor: "CreatedOn",
    hidden: false,
    width: 120,
  },
  {
    Header: "Modified On",
    accessor: "ModifiedOn",
    hidden: false,
    width: 120,
  },
  {
    Header: "Action",
    accessor: "Action",
    edit: true,
    delete: true,
    view: false,
    add: true,
    hidden: false,
    width: 100,
  },
];

export default {
  Get_Coupons,
  Get_Companies_dropdown,
  Save_Coupon,
  Edit_Coupon,
  Delete_Coupon,
  anchorTable,
};
