import { get, post } from "../api_helper";

const Get_Users = () => {
  let url = "api/Get_Users";

  return get(url);
};

const Get_Users_Dropdown = () => {
  let url = "api/Get_Users_Dropdown";

  return get(url);
};

const Save_User = body => {
  let url = "api/Save_User";
  return post(url, body);
};

const Edit_User = body => {
  let url = "api/Edit_User";
  return post(url, body);
};

const Delete_User = body => {
  let url = "api/Delete_User";
  return post(url, body);
};
const anchorTable = [
  {
    Header: "S.No..",
    accessor: "indexing",
    hidden: false,
    width: 50,
  },
  {
    Header: "First Name",
    accessor: "firstName",
    hidden: false,
  },
  {
    Header: "Last Name",
    accessor: "lastName",
    hidden: false,
  },
  {
    Header: "Email",
    accessor: "emailAddress",
    hidden: false,
  },
  {
    Header: "Country",
    accessor: "country",
    hidden: false,
  },
  {
    Header: "Mobile Number",
    accessor: "contact",
    hidden: false,
  },
  {
    Header: "IsActive",
    accessor: "IsActive",
    hidden: true,
    width: 100,
  },
  {
    Header: "Created By",
    accessor: "CreatedBy",
    hidden: false,
    width: 100,
  },
  {
    Header: "Created On",
    accessor: "CreatedOn",
    hidden: false,
    width: 120,
  },
  {
    Header: "Modified On",
    accessor: "ModifiedOn",
    hidden: false,
    width: 120,
  },
  {
    Header: "Action",
    accessor: "Action",
    edit: true,
    delete: false,
    view: false,
    add: true,
    hidden: false,
    width: 100,
  },
];

export default {
  Get_Users,
  Get_Users_Dropdown,
  Save_User,
  Edit_User,
  Delete_User,
  anchorTable,
};
