import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Loading from "components/Loading";
import ChangePassowrd from "../../components/Profile/PasswordChange";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { setDashboardFormValues, setDashboardPopupValues } from "store/actions";

import { Container, Row, Col, Card, Alert, CardBody } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import withRouter from "components/Common/withRouter";

import avatar from "../../assets/images/users/avatar-1.jpg";

function TabContainer(props) {
  const { children } = props;
  return <div style={{ paddingTop: 8 * 3 }}>{children}</div>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const UserProfile = () => {
  //meta title
  document.title = "Winzco - Change Password";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setname] = useState("");
  const [value, setValue] = useState(0);
  const [isLoading, setisLoading] = useState(false);

  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }));

  const User = useSelector(state => state.Login.User);
  const popupMsgValues = useSelector(state => state.Dashboard.popupMsgValues);
  const formValues = useSelector(state => state.Dashboard.formValues);

  const onCloseClick = () => {
    dispatch(setDashboardFormValues(null));
    dispatch(setDashboardPopupValues(null));
    if (formValues.success === true) {
      navigate("/dashboard");
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumb title="HR" breadcrumbItem="Profile" /> */}

          {/* <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody style={{ backgroundColor: "#2a3042f0", height: 200 }}>
                  <div className="d-flex">
                    <div style={{ margin: "auto", marginTop: 15 }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          alt={name}
                          src={avatar}
                          className="avatar-lg rounded-circle"
                          style={{
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: "100%",
                          }}
                        />
                      </div>
                      <Typography variant="h4" gutterBottom>
                        {name}
                      </Typography>
                      <Typography
                        gutterBottom
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "inherit",
                            color: "white",
                          }}
                        >
                          {User.First_Name + " " + User.Last_Name}
                        </span>
                      </Typography>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row> */}

          <AppBar position="static" style={{ backgroundColor: "#004a8d" }}>
            <Hidden smDown>
              <Tabs
                value={value}
                // onChange={handleChange}
                variant="fullWidth"
                indicatorColor="secondary"
                textColor="inherit"
                centered
              >
                <Tab label="CHANGE PASSWORD" />
              </Tabs>
            </Hidden>
          </AppBar>
          {value === 0 && (
            <TabContainer>
              <ChangePassowrd />
            </TabContainer>
          )}
        </Container>

        {/* DialogPopup */}
        <div>
          <Dialog
            open={popupMsgValues == null ? false : true}
            onClose={onCloseClick}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: 16,
                textDecoration: "underline solid black 2px",
              }}
            >
              <span style={{ color: "black" }}>Confirmation</span>
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                style={{ minWidth: 300, maxWidth: 400, color: "black" }}
              >
                {/* {popupMsgValues} */}
                {popupMsgValues === null ? "" : popupMsgValues}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {/* <NavLink to={path} style={{ textDecoration: "none" }}>
           
          </NavLink> */}
              <Button
                onClick={onCloseClick}
                style={{
                  color: "black",
                  fontWeight: "bold",
                  backgroundColor: "#3f51b5",
                }}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        {isLoading ? (
          <div
            style={{
              position: "fixed",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              justifyContent: "center",
              backgroundColor: "#333",
              opacity: 0.8,
            }}
            pointerEvents={"none"}
          >
            <Loading />
          </div>
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
