export const LOGIN_USER = "LOGIN_USER";
// export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const API_ERROR = "LOGIN_API_ERROR";

export const SOCIAL_LOGIN = "SOCIAL_LOGIN";

//----NEW
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_MESSAGE_CLEAR = "LOGIN_MESSAGE_CLEAR";
export const LOGOUT = "LOGOUT";
export const SET_NOTIFICATION = "SET_NOTIFICATION";
