import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  LOGIN_FAIL,
  LOGIN_ERROR,
  LOGOUT,
  LOGIN_MESSAGE_CLEAR,
  SET_NOTIFICATION,
} from "./actionTypes";

import userService from "../../../helpers/user.service";

// const initialState = {
//   error: "",
//   loading: false,
// }

const User = userService.Get_Login();
const initialState = User
  ? {
      isLoggedIn: true,
      User: User.UserInfo,
      Menu: User.Menu,
      Token: User.Token,
      Expiry: User.expiry,
      notifMsg: null,
      notification: [],
    }
  : {
      isLoggedIn: false,
      user: null,
      menu: null,
      token: null,
      expiry: null,
      notifMsg: null,
      notification: [],
    };
// const initialState = {
//   isLoggedIn: false,
//   user: null,
//   menu: null,
//   token: null,
//   expiry: null,
//   notifMsg: "",
// }

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    // case LOGIN_SUCCESS:
    //   state = {
    //     ...state,
    //     loading: false,
    //     isUserLogout: false,
    //   }
    //   break
    case LOGOUT_USER:
      state = { ...state };
      break;
    case LOGOUT_USER_SUCCESS:
      state = { ...state, isUserLogout: true };
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        isUserLogout: false,
      };
      break;
    //------NEW
    case LOGIN_SUCCESS:
      state = action.data;
      state = { ...state, isLoggedIn: true };
      break;
    case SET_NOTIFICATION:
      // state = action.data;
      state = { ...state, notification: action.data };
      break;
    case LOGIN_ERROR:
      draft.isLoggedIn = false;
      draft.user = null;
      break;
    case LOGIN_FAIL:
      state = { ...state, notifMsg: action.data };
      state.isLoggedIn = false;
      state.user = null;
      break;
    case LOGIN_MESSAGE_CLEAR:
      state.isLoggedIn = false;
      state.user = null;
      state.notifMsg = null;
      break;
    case LOGOUT:
      state = {
        user: null,
        menu: null,
        token: null,
        expiry: null,
        notifMsg: null,
      };
      state.isLoggedIn = false;
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
