import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import _PushNotifications from "helpers/PushNotifications";
import _Users from "helpers/Users";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Loading from "components/Loading";
import _validation from "helpers/validation";
import {
  addNewItem,
  updateItem,
  setPopupMessageValues,
  toggleForm,
  setFormValues,
} from "store/actions";

import {
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

const floatingForm = props => {
  const { branch } = props;

  const [isEdit, setIsEdit] = useState(false);
  const [IsActiveCheck, setIsActiveCheck] = useState(true);
  const [InitialFormData, setInitialFormData] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  // const [users, setUsers] = useState(null);

  const formOpen = useSelector(state => state.crudTbPushNotifications.formOpen);
  const PushNotificationsList = useSelector(
    state => state.crudTbPushNotifications.dataTable
  );
  const formValues = useSelector(
    state => state.crudTbPushNotifications.formValues
  );
  const user = useSelector(state => state.Login.User);
  const dispatch = useDispatch();
  useEffect(() => {
    if (formValues != null) {
      const index = PushNotificationsList.findIndex(
        item => item._id == formValues._id
      );
      setIsEdit(true);
      setInitialFormData(PushNotificationsList[index]);
      setIsActiveCheck(PushNotificationsList[index].IsActive);
    } else {
      setIsEdit(false);
    }
    // fetchData();
  }, [formOpen]);
  const toggle = () => {
    dispatch(toggleForm(false, branch));
    dispatch(setFormValues(null, branch));
  };

  // const fetchData = async () => {
  //   setisLoading(true);
  //   const response = await _Users.Get_Users_Dropdown();
  //   if (response.IsSuccess === true && response.Status_Code === 200) {
  //     let data = response.Data;
  //     setUsers(data);
  //   } else {
  //     console.log(response.message);
  //     setisLoading(false);
  //     dispatch(setPopupMessageValues(response.message, branch));
  //   }
  //   setisLoading(false);
  // };

  // const renderusers = () =>
  //   users.map((item, index) => (
  //     <option key={index} value={item._id}>
  //       {item.firstName + " " + item.lastName}
  //     </option>
  //   ));

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      date: isEdit ? InitialFormData.date : "",
      message: isEdit ? InitialFormData.message : "",
      // userId: isEdit ? InitialFormData.userId : "",
      // IsActive: isEdit ? InitialFormData.IsActive : "",
    },
    validationSchema: Yup.object({
      date: Yup.date().required("Please Enter date."),
      message: Yup.string().required("Please Enter message."),
      // userId: Yup.string().required("Please Select User."),
    }),
    onSubmit: async values => {
      setisLoading(true);
      if (isEdit) {
        //While updating an existing data
        let body = {
          _id: InitialFormData._id,
          date: values.date,
          message: values.message,
          // userId: values.userId,
          IsActive: IsActiveCheck,
          User: user.id,
        };
        const response = await _PushNotifications.Edit_PushNotification(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          dispatch(updateItem(data, branch));
          setisLoading(false);
          dispatch(setPopupMessageValues(response.message, branch));
        } else {
          console.log(response.message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.message, branch));
        }
      } else {
        //While Adding new Data
        let body = {
          date: values.date,
          message: values.message,
          // userId: values.userId,
          IsActive: IsActiveCheck,
          User: user.id,
        };
        const response = await _PushNotifications.Save_PushNotification(body);
        if (response.IsSuccess === true && response.Status_Code === 200) {
          let data = response.Data;
          dispatch(addNewItem(data, branch));
          setisLoading(false);
          dispatch(setPopupMessageValues(response.message, branch));
        } else {
          console.log(response.message);
          setisLoading(false);
          dispatch(setPopupMessageValues(response.message, branch));
        }
      }
      toggle();
    },
  });
  return (
    <Modal isOpen={formOpen} centered>
      <ModalHeader
        toggle={toggle}
        tag="h5"
        className="py-3 px-4 border-bottom-0"
      >
        {!!isEdit ? "Edit PushNotifications" : "Add PushNotifications"}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form
          onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Date
                </Label>
                <Input
                  name="date"
                  type="datetime-local"
                  // maxLength={255}
                  // onKeyPressCapture={e => _validation.alphaNumeric(e)}
                  // value={isEdit ? InitialFormData.name : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.date || ""}
                  invalid={
                    validation.touched.date && validation.errors.date
                      ? true
                      : false
                  }
                />
                {validation.touched.date && validation.errors.date ? (
                  <FormFeedback type="invalid">
                    {validation.errors.date}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            {/* <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Date
                </Label>
                <Input
                  name="date"
                  type="text"
                  maxLength={255}
                  onKeyPressCapture={e => _validation.alphaNumeric(e)}
                  // value={isEdit ? InitialFormData.name : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.date || ""}
                  invalid={
                    validation.touched.date && validation.errors.date
                      ? true
                      : false
                  }
                />
                {validation.touched.date && validation.errors.date ? (
                  <FormFeedback type="invalid">
                    {validation.errors.date}
                  </FormFeedback>
                ) : null}
              </div>
            </Col> */}
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Message
                </Label>
                <Input
                  name="message"
                  type="text"
                  maxLength={255}
                  onKeyPressCapture={e => _validation.alphaNumeric(e)}
                  // value={isEdit ? InitialFormData.name : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.message || ""}
                  invalid={
                    validation.touched.message && validation.errors.message
                      ? true
                      : false
                  }
                />
                {validation.touched.message && validation.errors.message ? (
                  <FormFeedback type="invalid">
                    {validation.errors.message}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            {/* <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>User
                </Label>
                <Input
                  type="select"
                  name="userId"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  required
                  value={validation.values.userId || ""}
                  invalid={
                    validation.touched.userId && validation.errors.userId
                      ? true
                      : false
                  }
                >
                  <option key={1} value={0}>
                    Select
                  </option>
                  {users == null || users == undefined ? "" : renderusers()}
                </Input>
                {validation.touched.userId && validation.errors.userId ? (
                  <FormFeedback type="invalid">
                    {validation.errors.userId}
                  </FormFeedback>
                ) : null}
              </div>
            </Col> */}
            <Col>
              <FormControlLabel
                control={
                  <Switch
                    name="IsActive"
                    onChange={e => setIsActiveCheck(e.target.checked)}
                    checked={IsActiveCheck}
                    color="primary"
                  />
                }
                label="Is Active"
              />
            </Col>
          </Row>

          <Row className="mt-2">
            <Col className="col-12 text-end">
              <button
                type="button"
                className="btn btn-light me-2"
                onClick={toggle}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="btn-save-event"
              >
                Push
              </button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      {/* <DialogPopup
        show={showDialogPopup}
        // onCloseClick={onCloseClick}
        message={message}
      /> */}
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

floatingForm.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default floatingForm;
