import { get, post } from "../api_helper";

const Get_Orders = body => {
  let url = "api/Get_Orders";

  return get(url, body);
};

const Get_Companies_dropdown = body => {
  let url = "Campaigns/Get_Companies_dropdown";

  return get(url, body);
};

const Save_Order = body => {
  let url = "api/Save_Order";
  return post(url, body);
};

const Edit_Order = body => {
  let url = "api/Edit_Order";
  return post(url, body);
};

const Delete_Order = body => {
  let url = "api/Delete_Order";
  return post(url, body);
};
const anchorTable = [
  {
    Header: "S.No.",
    accessor: "indexing",
    hidden: false,
    width: 50,
  },
  {
    Header: "Order Number",
    accessor: "orderNumber",
    hidden: false,
  },
  {
    Header: "Order DateTime",
    accessor: "orderDateTime",
    hidden: false,
  },
  {
    Header: "Customer Name",
    accessor: "customerName",
    hidden: false,
  },
  {
    Header: "Customer Email",
    accessor: "customerEmail",
    hidden: false,
  },
  {
    Header: "Contact",
    accessor: "phone",
    hidden: false,
  },
  {
    Header: "Address",
    accessor: "address",
    hidden: false,
  },
  {
    Header: "Country",
    accessor: "country",
    hidden: false,
  },
  {
    Header: "Payment Type",
    accessor: "paymentType",
    hidden: false,
  },
  {
    Header: "IsActive",
    accessor: "IsActive",
    hidden: true,
    width: 100,
  },
  {
    Header: "Created By",
    accessor: "CreatedBy",
    hidden: true,
    width: 100,
  },
  {
    Header: "Created On",
    accessor: "CreatedOn",
    hidden: true,
    width: 120,
  },
  {
    Header: "Modified On",
    accessor: "ModifiedOn",
    hidden: true,
    width: 120,
  },
  {
    Header: "Action",
    accessor: "Action",
    edit: true,
    delete: false,
    view: false,
    add: false,
    hidden: true,
    width: 100,
  },
];

export default {
  Get_Orders,
  Get_Companies_dropdown,
  Save_Order,
  Edit_Order,
  Delete_Order,
  anchorTable,
};
