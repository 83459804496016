import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
  LOGIN_FAIL,
  LOGIN_ERROR,
  LOGOUT,
  LOGIN_MESSAGE_CLEAR,
  SET_NOTIFICATION,
} from "./actionTypes";

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  };
};

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  };
};

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { data, history, type },
  };
};

//---------NEW
export const login = data => ({
  type: LOGIN_SUCCESS,
  data,
});
export const setNotificaation = data => ({
  type: SET_NOTIFICATION,
  data,
});
export const login_fail = data => ({
  type: LOGIN_FAIL,
  data,
});
export const login_message_clear = () => ({
  type: LOGIN_MESSAGE_CLEAR,
});
export const login_error = data => ({
  type: LOGIN_ERROR,
  data,
});
export const logout = () => ({
  type: LOGOUT,
});
