import React, { useState } from "react";
import { Container } from "reactstrap";
import Loading from "components/Loading";
import DialogPopup from "components/Common/DialogPopup";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import _TermsAndConditions from "helpers/TermsAndConditions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setData, setPopupMessageValues } from "store/actions";
import {
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
const TermsAndConditions = props => {
  //meta title
  const dispatch = useDispatch();
  document.title = "Winzco - Terms & Conditions";
  const branch = "crudTbTermsAndConditions";
  const [isLoading, setisLoading] = useState(false);

  const [text, setText] = useState("");

  const popupMessage = useSelector(
    state => state.crudTbTermsAndConditions.popupMessage
  );
  const user = useSelector(state => state.Login.User);

  const initialData = async () => {
    try {
      setisLoading(true);
      const response = await _TermsAndConditions.Get_Terms_And_Conditions();
      console.log(response);
      if (
        response.IsSuccess === true &&
        response.Status_Code === 200 &&
        response.Data.length > 0
      ) {
        console.log(response.Data[0].text);
        setText(response.Data[0].text);
        setisLoading(false);
      } else {
        setisLoading(false);
      }
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
      setisLoading(false);
    }
  };

  React.useEffect(() => {
    initialData();
  }, []);

  const handleSave = async () => {
    setisLoading(true);
    if (text !== "") {
      let body = {
        text: text,
        User: user.id,
      };
      const response = await _TermsAndConditions.Save_Terms_And_Conditions(
        body
      );
      if (response.IsSuccess === true && response.Status_Code === 200) {
        setisLoading(false);
        dispatch(setPopupMessageValues(response.message, branch));
      } else {
        console.log(response.message);
        setisLoading(false);
        dispatch(setPopupMessageValues(response.message, branch));
      }
    } else {
      setisLoading(false);
      dispatch(setPopupMessageValues("Enter Terms & Conditions.", branch));
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("")}
            breadcrumbItem={props.t("Terms&Conditions")}
          />

          <Row>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  <span style={{ color: "red" }}>* </span>Enter Terms &
                  Conditions
                </Label>
                <textarea
                  type="text"
                  className="form-control"
                  value={text}
                  onChange={e => setText(e.target.value)}
                  rows={6}
                  accept="image/jpeg, image/png,"
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="col-12 text-start">
              <button
                onClick={handleSave}
                className="btn btn-success"
                id="btn-save-event"
              >
                Save
              </button>
            </Col>
          </Row>
          {/* <Row className="mt-2">
            <Col className="col-6 text-start">
              {image.length > 0 ? (
                <img
                  className=" header-profile-user"
                  src={image}
                  alt="img"
                  style={{
                    margin: 3,
                    border: "1px solid black",
                    width: "100%",
                    height: "100%",
                  }}
                />
              ) : (
                "No image found!"
              )}
            </Col>
          </Row> */}
        </Container>
      </div>

      {/* DialogPopup */}
      <DialogPopup branch={branch} message={popupMessage} />

      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default withTranslation()(TermsAndConditions);
