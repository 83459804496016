import { get, post } from "../api_helper";

const Get_Terms_And_Conditions = body => {
  let url = "api/Get_Terms_And_Conditions";

  return get(url, body);
};

const Save_Terms_And_Conditions = body => {
  let url = "api/Save_Terms_And_Conditions";

  return post(url, body);
};

export default {
  Get_Terms_And_Conditions,
  Save_Terms_And_Conditions,
};
