import {
  SET_DATA,
  ADD_NEW_ITEM,
  UPDATE_ITEM,
  REMOVE_ITEM,
  TOGGLE_FORM,
  SET_FORM_VALUES,
  SET_POP_MESSAGE_VALUES,
} from "../crudTbFrmConstant";

const Initial_State = {
  dataTable: [],
  error: {},
  formOpen: false,
  formValues: null,
  popupMessage: null,
};

const Designation = (state = Initial_State, action) => {
  const { branch } = action;
  switch (action.type) {
    case `${branch}/${SET_DATA}`:
      return {
        ...state,
        dataTable: action.payload,
      };

    case `${branch}/${TOGGLE_FORM}`:
      return {
        ...state,
        formOpen: action.payload,
      };

    case `${branch}/${SET_FORM_VALUES}`: {
      return {
        ...state,
        formValues: action.payload,
      };
    }

    case `${branch}/${SET_POP_MESSAGE_VALUES}`: {
      return {
        ...state,
        popupMessage: action.payload,
      };
    }

    case `${branch}/${ADD_NEW_ITEM}`: {
      let newTable = state.dataTable;
      newTable.push(action.payload);
      return {
        ...state,
        dataTable: newTable,
        formOpen: false,
        formValues: null,
      };
    }

    case `${branch}/${UPDATE_ITEM}`: {
      let userTable = state.dataTable;
      const index = userTable.findIndex(
        item => item._id === action.payload._id
      );
      if (index !== -1) {
        userTable[index] = action.payload;
        return {
          ...state,
          dataTable: userTable,
          formOpen: false,
          formValues: null,
        };
      }
      break;
    }

    case `${branch}/${REMOVE_ITEM}`: {
      const userTable = state.dataTable;
      const index = userTable.findIndex(
        item => item._id === action.payload._id
      );
      if (index !== -1) {
        return {
          ...state,
          dataTable: userTable.filter(item => {
            return item._id !== action.payload._id;
          }),
        };
      }
      break;
    }
    default:
      return state;
  }
};

export default Designation;
