var CryptoJS = require("crypto-js");

const key = CryptoJS.enc.Utf8.parse("(&#MX98cn342cn4*&X#&(UJYHJCX**38");
const iv = CryptoJS.enc.Utf8.parse("n685vCX7&5@$%#(M");

const encrypt = data => {
  var cipherData = CryptoJS.AES.encrypt(data, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
    blockSize: 128,
    keySize: 256,
  });

  return cipherData.toString();
};

const encrypt_url = data => {
  var encrypted_data = encrypt(data);
  return encodeURIComponent(encrypted_data);
};

const decrypt_url = data => {
  var decrypted_data = decrypt(data);
  return decodeURIComponent(decrypted_data);
};
const decrypt = data => {
  var rawData = CryptoJS.enc.Base64.parse(data);
  var plaintextData = CryptoJS.AES.decrypt({ ciphertext: rawData }, key, {
    iv: iv,
  });
  let plaintext = plaintextData.toString(CryptoJS.enc.Utf8);
  if (plaintext.trim() === "") {
    plaintext = data;
  }
  return plaintext;
};

export default {
  encrypt,
  decrypt,
  encrypt_url,
  decrypt_url,
};
