import React from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Person from "@material-ui/icons/Person";
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  List,
} from "@material-ui/core";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
// import datas from "dan-api/apps/connectionData";

function Connection(props) {
  const { classes, data } = props;

  return (
    <>
      {data.length > 0 ? (
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          direction="row"
          spacing={3}
        >
          <Grid item md={6} xs={12}>
            <Grid item md={6} xs={12}>
              <Grid md={12}>
                <div style={{ display: "flex", color: "black" }}>
                  <Typography
                    variant="h6"
                    component="h2"
                    className={classes.title}
                    style={{ marginTop: 12, fontSize: 22 }}
                  >
                    EMPLOYEE FUNCTION
                  </Typography>
                </div>
              </Grid>
              {data.map((data, index) => {
                return (
                  <List
                    dense
                    key={index}
                    className={classes.profileList}
                    style={{
                      backgroundColor: "white",
                      boxShadow: "0px 0px 10px grey",
                      borderRadius: 10,
                      margin: "10px 0px",
                      padding: 10,
                    }}
                  >
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar style={{ backgroundColor: "#004a8d" }}>
                          <i className="fas fa-users-cog"></i>
                        </Avatar>
                      </ListItemAvatar>
                      <div>
                        <Typography
                          style={{
                            fontSize: 15,
                            fontWeight: "bold",
                            color: "#2c2f42",
                          }}
                        >
                          {data.Function_Name}
                        </Typography>
                      </div>
                    </ListItem>
                  </List>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          direction="row"
          spacing={3}
        >
          <Grid
            item
            md={6}
            xs={12}
            style={{
              backgroundColor: "#2a3042f0",
              borderRadius: 30,
              color: "white",
            }}
          >
            <div style={{ display: "flex" }}>
              <Avatar style={{ margin: 15 }}>
                {/* <Person /> */}
                <i
                  className="fa fa-info-circle"
                  style={{ fontSize: 20 }}
                  aria-hidden="true"
                ></i>
              </Avatar>
              <Typography
                variant="h6"
                component="h2"
                className={classes.title}
                style={{ marginTop: 12, fontSize: 25 }}
              >
                Functions
              </Typography>
            </div>
            {/* About Me */}
            <List
              dense
              className={classes.profileList}
              style={{
                backgroundColor: "white",
                boxShadow: "0px 0px 10px grey",
                borderRadius: 30,
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                style={{
                  margin: "10px 10px",
                  fontWeight: "bold",
                  color: "#2c2f42",
                }}
              >
                Unfortunately you don't have any Academic Information available.
              </Typography>
            </List>
          </Grid>
        </Grid>
      )}
    </>
  );
}

Connection.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles()(Connection);

// <div style={{ display: "flex" }}>
//               <Avatar style={{ margin: 15 }}>
//                 {/* <Person /> */}
//                 <i
//                   className="fa fa-info-circle"
//                   style={{ fontSize: 20 }}
//                   aria-hidden="true"
//                 ></i>
//               </Avatar>
//               <Typography
//                 variant="h6"
//                 component="h2"
//                 className={classes.title}
//                 style={{ marginTop: 12, fontSize: 25 }}
//               >
//                 Functions
//               </Typography>
//             </div>
//             {/* About Me */}
//             <List
//               dense
//               // className={classes.profileList}
//               style={{
//                 backgroundColor: "#5A5C6B",
//                 borderRadius: 30,
//                 display: "flex",
//                 flexDirection: "column",
//                 // maxHeight: 350,
//                 // overflowY: "scroll",
//               }}
//             >
//               {data.map((data, index) => {
//                 return (
//                   <Grid key={index} md={6}>
//                     <ListItem>
//                       <ListItemAvatar>
//                         <Avatar>
//                           <i className="fas fa-users-cog"></i>
//                         </Avatar>
//                       </ListItemAvatar>
//                       <div>
//                         {/* <Typography style={{ fontWeight: "bold" }}>
//                           {index + 1}
//                         </Typography> */}
//                         <Typography style={{ fontSize: 15 }}>
//                           {data.Function_Name}
//                         </Typography>
//                       </div>
//                     </ListItem>
//                   </Grid>
//                 );
//               })}
//             </List>
