import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

import avatar1 from "../../assets/images/users/avatar-1.jpg";
import profileImg from "../../assets/images/profile-img.png";
import background from "assets/images/brands/background.jpg";

const WelcomeComp = () => {
  const user = useSelector(state => state.Login.User);
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary ">
          <Row
            style={{
              backgroundImage: `url(${background})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              color: "white",
            }}
          >
            <Col xs="7">
              <div className="text-white p-4">
                <h5 className="text-white">Welcome To</h5>
                <p>Winzco Admin Pannel</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="3">
              <div
                className="avatar-md profile-user-wid mb-4"
                style={{ margin: "auto", marginTop: -15 }}
              >
                <img
                  src={
                    user.User_Photo_Path !== null && user.User_Photo_Path !== ""
                      ? user.User_Photo_Path
                      : profileImg
                  }
                  alt=""
                  className="img-thumbnail rounded-circle"
                />
              </div>
              <h5
                style={{
                  textAlign: "center",
                }}
              >
                {user.first_name ? user.first_name : "Admin"}
              </h5>
              {/* <p className="text-muted mb-0 text-truncate">
                {user.Designation_Name + " - " + user.Department_Name}
              </p> */}
            </Col>

            <Col sm="9">
              <div className="pt-4">
                {/* <Row>
                  <Col xs="4">
                    <p className="text-muted mb-0">Date of Joining</p>
                    <h5 className="font-size-15">
                      {moment(user.DOJ.split("T")[0]).format("DD-MM-YYYY")}
                    </h5>
                  </Col>
                  <Col xs="4">
                    <p className="text-muted mb-0">Department</p>
                    <h5 className="font-size-15">{user.Department_Name}</h5>
                  </Col>
                  <Col xs="4">
                    <p className="text-muted mb-0">Designation</p>
                    <h5 className="font-size-15">{user.Designation_Name}</h5>
                  </Col>
                </Row> */}
                {/* <div className="mt-4">
                  <Link
                    to="/profile"
                    className="btn btn-primary  btn-sm"
                    style={{ backgroundColor: "#004a8d" }}
                  >
                    View Profile <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div> */}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default WelcomeComp;
