import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Error from "@material-ui/icons/RemoveCircle";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import SimpleBar from "simplebar-react";

//Notification
import { HubConnectionBuilder } from "@microsoft/signalr";
import Ticket_Notification from "helpers/Notification/index";

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";

//i18n
import { withTranslation } from "react-i18next";

import { setNotificaation } from "store/actions";

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notifications = useSelector(state => state.Login.notification);

  //------------- Popup Notification------------------------------------
  const [connection, setConnection] = useState(null);
  const [Notification, setNotification] = useState([
    {
      Ticket: 321000123451,
      User: "Farhan1",
    },
    {
      Ticket: 321000123452,
      User: "Farhan2",
    },
    {
      Ticket: 321000123453,
      User: "Farhan3",
    },
    {
      Ticket: 321000123454,
      User: "Farhan4",
    },
    {
      Ticket: 321000123455,
      User: "Farhan5",
    },
  ]);
  const [menuState, setMenuState] = useState({
    anchorEl: null,
    openMenu: null,
  });
  const latestChat = useRef(null);
  latestChat.current = Notification;
  const API_URL = "http://43.231.61.149/Winzco/";

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(API_URL + "hubs/notifications")
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
  }, []);
  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(result => {
          console.log("Connected!");

          connection.on("ReceiveMessage", message => {
            const msg = JSON.parse(message);
            if (msg.User + "" === User.Employee_Id + "") {
              const updatedNotification = [...latestChat.current];
              updatedNotification.push(msg);
              setNotification(updatedNotification);
              Ticket_Notification.Received_Notification(msg.Ticket, msg.User);
              localStorage.setItem(
                "Notification",
                JSON.stringify(updatedNotification)
              );
              console.log(updatedNotification);
              dispatch(setNotificaation(updatedNotification));
            }
          });
        })
        .catch(e => console.log("Connection failed: ", e));
    }
    // dispatch(
    //   setNotificaation([
    //     {
    //       Ticket: 321000123451,
    //       User: "Farhan1",
    //     },
    //     {
    //       Ticket: 321000123452,
    //       User: "Farhan2",
    //     },
    //     {
    //       Ticket: 321000123453,
    //       User: "Farhan3",
    //     },
    //     {
    //       Ticket: 321000123454,
    //       User: "Farhan4",
    //     },
    //     {
    //       Ticket: 321000123455,
    //       User: "Farhan5",
    //     },
    //   ])
    // );
  }, [connection]);

  // const handleMenu = menu => event => {
  //   const { openMenu } = menuState;
  //   setMenuState({
  //     openMenu: openMenu === menu ? null : menu,
  //     anchorEl: event.currentTarget,
  //   });
  // };

  // const handleClose = () => {
  //   setMenuState({ anchorEl: null, openMenu: null });
  // };

  // const { anchorEl, openMenu } = menuState;

  useEffect(() => {
    var _notification = localStorage.getItem("Notification");
    if (_notification !== null) {
      //  setNotification(_notification);
      setNotification(JSON.parse(_notification));
      // setNotification();
      dispatch(setNotificaation(JSON.parse(_notification)));
    }
  }, []);

  useEffect(() => {
    setNotificaation(notifications);
  }, [notifications]);

  // const handleMenu = (menu) => (event) => {
  //   const { openMenu } = menuState;
  //   setMenuState({
  //     openMenu: openMenu === menu ? null : menu,
  //     anchorEl: event.currentTarget,
  //   });
  // };

  // const handleClose = () => {
  //   setMenuState({ anchorEl: null, openMenu: null });
  // };
  //------------- Popup Notification
  // console.log(Notification);
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          <span className="badge bg-danger rounded-pill">
            {Notification.length}
          </span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              {/* <div className="col-auto">
                <a href="#" className="small">
                  {" "}
                  View All
                </a>
              </div> */}
            </Row>
          </div>

          <SimpleBar style={{ maxHeight: "230px" }}>
            {/* <Menu
              id="menu-notification"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              // className={classes.notifMenu}
              PaperProps={{
                style: {
                  width: 350,
                },
              }}
              open={openMenu === "notification"}
              onClose={handleClose}
            >
              {Notification !== null
                ? Notification.map((item, index) => (
                    <MenuItem
                      onClick={() => {
                        try {
                          const updatedNotification = [...latestChat.current];

                          const index = updatedNotification.findIndex(
                            itemm => itemm.Ticket === item.Ticket
                          );

                          if (index !== -1) {
                            updatedNotification.splice(index, 1);
                          }

                          localStorage.setItem(
                            "Notification",
                            JSON.stringify(updatedNotification)
                          );
                        } catch (error) {}

                        Ticket_Notification.Read_Notification(
                          item.Ticket,
                          item.User
                        );

                        setMenuState({ anchorEl: null, openMenu: null });

                        localStorage.setItem("tracking_ticket", item.Ticket);
                        window.location.href =
                          "/app/Ticket_management/Ticket_Tracking";
                      }}
                      key={index}
                    >
                      <div className={messageStyles.messageError}>
                        <ListItemAvatar>
                          <Avatar className={messageStyles.icon}>
                            <Error />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={`Ticket assigned to you`}
                          className={classes.textNotif}
                          secondary={`Ticket #: ${item.Ticket}`}
                        />
                      </div>
                    </MenuItem>
                  ))
                : "You don't have any notification right now."}
            </Menu> */}
            {Notification !== null && Notification.length > 0 ? (
              Notification.map((item, index) => (
                <MenuItem
                  onClick={() => {
                    try {
                      const updatedNotification = [...latestChat.current];

                      const index = updatedNotification.findIndex(
                        itemm => itemm.Ticket === item.Ticket
                      );

                      if (index !== -1) {
                        updatedNotification.splice(index, 1);
                      }

                      localStorage.setItem(
                        "Notification",
                        JSON.stringify(updatedNotification)
                      );
                      dispatch(setNotificaation(updatedNotification));
                    } catch (error) {}

                    Ticket_Notification.Read_Notification(
                      item.Ticket,
                      item.User
                    );

                    setMenuState({ anchorEl: null, openMenu: null });

                    localStorage.setItem("tracking_ticket", item.Ticket);
                    window.location.href = "/dashboard";
                    // navigate("/master/department");
                  }}
                  key={index}
                >
                  <div
                  //  className={messageStyles.messageError}
                  >
                    {/* <ListItemAvatar>
                      <Avatar
                      // className={messageStyles.icon}
                      >
                        <Error />
                      </Avatar>
                    </ListItemAvatar> */}
                    <ListItemText
                      primary={`Ticket assigned to you`}
                      // className={classes.textNotif}
                      secondary={`Ticket #: ${item.Ticket}`}
                    />
                  </div>
                </MenuItem>
              ))
            ) : (
              <div className="d-flex p-3">
                {/* <div className="avatar-xs me-3">
                <span className="avatar-title bg-primary rounded-circle font-size-16">
                  <i className="bx bx-cart" />
                </span>
              </div> */}
                <div className="flex-grow-1">
                  {/* <h6 className="mt-0 mb-1">{props.t("Notifications")}</h6> */}
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {props.t("You don't have any notification.")}
                    </p>
                    {/* <p className="mb-0">
                    <i className="mdi mdi-clock-outline" />{" "}
                    {props.t("3 min ago")}{" "}
                  </p> */}
                  </div>
                </div>
              </div>
            )}
            {/* <Link to="" className="text-reset notification-item">
              <div className="d-flex">
                <div className="avatar-xs me-3">
                  <span className="avatar-title bg-primary rounded-circle font-size-16">
                    <i className="bx bx-cart" />
                  </span>
                </div>
                <div className="flex-grow-1">
                  <h6 className="mt-0 mb-1">
                    {props.t("Your order is placed")}
                  </h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {props.t("If several languages coalesce the grammar")}
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" />{" "}
                      {props.t("3 min ago")}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </Link> */}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            {/* <Link
              className="btn btn-sm btn-link font-size-14 text-center"
              to="#"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              <span key="t-view-more">{props.t("View More..")}</span>
            </Link> */}
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};
