import React, { Fragment, useState, useCallback, useEffect } from "react";
import Moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Loading from "components/Loading";
import TablePagination from "@material-ui/core/TablePagination";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { Table, Row, Col, Button, Input } from "reactstrap";
import { Navigate, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Encryption from "helpers/Encryption/Encryption";
import user1 from "../../assets/images/users/avatar-1.jpg";

import {
  toggleForm,
  removeItem,
  setData,
  setFormValues,
  setPopupMessageValues,
  updateItem,
} from "store/actions";
import del from "helpers/del";
// import changeActiveStatus from "helpers/changeActiveStatus";
import DeleteModal from "./DeleteModal";
// import StatusChangeModal from "./StatusChangeModal";
import _Tickets from "helpers/Tickets";

const TableContainer = props => {
  const {
    columns,
    initialData,
    className,
    branch,
    Date_To,
    Date_From,
    EmployeeId,
  } = props;
  const navigate = useNavigate();

  // const findSort = () => {
  //   let sort = "";
  //   for (const ini of columns) {
  //     if (ini.IsSort === true) {
  //       sort = ini.Header;
  //       break;
  //     }
  //   }
  //   return sort;
  // };

  const [DataList, setDataList] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  const [DataToDelete, setDataToDelete] = useState(null);
  const [changeStatusModal, setchangeStatusModal] = useState(false);
  const [StatusChangeItem, setStatusChangeItem] = useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [TotalNoOfRows, setTotalNoOfRows] = React.useState(0);
  const [IsAddButton, setIsAddButton] = React.useState(false);

  //Search and Sort
  const [rows, setRows] = React.useState([]);
  const [search, setsearch] = React.useState("");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState();

  const user = useSelector(state => state.Login.User);
  const dispatch = useDispatch();

  const checkAddButton = () => {
    const add = columns.filter(item => {
      return item.accessor === "Action" && item.add === true;
    });
    if (add.length > 0) {
      setIsAddButton(true);
    } else {
      setIsAddButton(false);
    }
  };

  //Search and Sort
  const requestSearch = async searchedVal => {
    const searchWord = searchedVal;
    if (searchWord !== "") {
      let filterData = [];
      const newFilter = Object.keys(DataList).reduce((result, key) => {
        let checkData = false;
        Object.keys(DataList[key]).forEach(function (keys) {
          let textdata = DataList[key][keys] + "";
          if (textdata.toLowerCase().includes(searchWord.toLowerCase())) {
            checkData = true;
          }
        });

        if (checkData) {
          filterData.push(DataList[key]);
        }
      }, []);

      setRows(filterData);
      // setDataList(filterData);
    } else {
      setRows(DataList);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const createSortHandler = property => event => {
    handleRequestSort(event, property);
  };
  //Search and Sort

  React.useEffect(() => {
    if (initialData != null) {
      checkAddButton();
      setTotalNoOfRows(initialData.length);
      requestSearch("");
      setsearch("");
      setDataList(initialData);
      setRows(initialData);
    }
  }, [initialData]);

  const EditButtons = item => {
    switch (branch) {
      case "crudTbNewsArticles":
        return (
          <Link
            to={`/news_and_articles_addition?q=${Encryption.encrypt_url(
              "id=" + item.NewsArticle_Id
            )}`}
          >
            <Button color="red" size="sm" onClick={e => {}}>
              <i className="fas fa-pen"></i>
            </Button>
          </Link>
        );
      case "crudTbIngredient":
        return (
          <Link to={`/add_ingredient?q=${Encryption.encrypt_url(item._id)}`}>
            <Button color="red" size="sm" onClick={e => {}}>
              <i className="fas fa-pen"></i>
            </Button>
          </Link>
        );
      case "crudTbProduct":
        return (
          <Link
            to={`/add_ingredient?q=${Encryption.encrypt_url(item.Product_Id)}`}
          >
            <Button color="red" size="sm" onClick={e => {}}>
              <i className="fas fa-pen"></i>
            </Button>
          </Link>
        );
      default:
        return (
          <Button
            color="red"
            size="sm"
            onClick={e => {
              handleEdit(item);
            }}
          >
            <i className="fas fa-pen"></i>
          </Button>
        );
    }
  };

  const handleChangeRowsPerPage = event => {
    const totalRows = event.target.value;
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    onPageRowChange(totalRows, 0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const addNewItem = () => {
    if (branch === "crudTbNewsArticles") {
      navigate("/news_and_articles_addition");
    } else {
      dispatch(toggleForm(true, branch));
    }
  };

  const handleView = item => {
    // dispatch(toggleForm(true, branch));
  };

  const handleEdit = item => {
    dispatch(setFormValues(item, branch));
    dispatch(toggleForm(true, branch));
  };

  const eventDel = useCallback(
    async item => {
      setisLoading(true);
      const del_response = await del.delAction(branch, item, user.id);
      if (del_response.Status_Code === 200 && del_response.IsSuccess === true) {
        setdeleteModal(false);
        dispatch(removeItem(item, branch));
        setisLoading(false);
        dispatch(setPopupMessageValues(del_response.message, branch));
      } else {
        setdeleteModal(false);
        dispatch(setPopupMessageValues(del_response.message, branch));
        setisLoading(false);
      }
    },
    [branch]
  );

  const handleDeclareWinner = async row => {
    setisLoading(true);
    const body = {
      ...row,
      User: user.id,
    };
    const resp = await _Tickets.Declare_Winner(body);
    if (resp.Status_Code === 200 && resp.IsSuccess === true) {
      setisLoading(false);
      dispatch(setPopupMessageValues(resp.message, branch));
    } else {
      setisLoading(false);
      dispatch(setPopupMessageValues(resp.message, branch));
    }
  };

  const handleRowDelete = row => {
    setdeleteModal(true);
    setDataToDelete(row);
  };

  const renderRows = () =>
    // stableSort(rows, getComparator(order, orderBy))
    rows
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row, index1) => {
        return (
          <tr key={index1}>
            {columns.map((cell, index) => {
              return (
                cell.hidden === false && (
                  <td
                    key={index}
                    // width={cell.width !== undefined && cell.width}
                    style={{
                      margin: 0,
                      width: cell.width !== undefined ? cell.width : 300,
                    }}
                  >
                    {cell.accessor === "Action" ? (
                      <div>
                        {cell.view === true ? (
                          <Button
                            color="red"
                            size="sm"
                            onClick={e => {
                              handleView(row);
                            }}
                          >
                            <i className="fas fa-eye"></i>
                          </Button>
                        ) : (
                          " "
                        )}
                        {cell.edit === true ? EditButtons(row) : ""}
                        {/* {cell.edit === true && row["IsEditable"] === true
                        ? EditButtons(row)
                        : ""} */}
                        {cell.delete === true ? (
                          branch === "crudTbEmployeeLeave" &&
                          row["ApprovalStatus_Name"] === "PENDING" ? (
                            <Button
                              color="red"
                              size="sm"
                              onClick={e => {
                                // eventDel(row);
                                handleRowDelete(row);
                              }}
                            >
                              <i className="fas fa-trash"></i>
                            </Button>
                          ) : branch === "crudTbEmployeeLeave" &&
                            row["ApprovalStatus_Name"] !== "Pending" ? (
                            ""
                          ) : (
                            <Button
                              color="red"
                              size="sm"
                              onClick={e => {
                                // eventDel(row);
                                handleRowDelete(row);
                              }}
                            >
                              <i className="fas fa-trash"></i>
                            </Button>
                          )
                        ) : (
                          ""
                        )}
                        {/* {cell.delete === true && row["IsEditable"] === true ? (
                        <Button
                          color="red"
                          size="sm"
                          onClick={e => {
                            // eventDel(row);
                            handleRowDelete(row);
                          }}
                        >
                          <i className="fas fa-trash"></i>
                        </Button>
                      ) : (
                        ""
                      )} */}
                      </div>
                    ) : cell.accessor === "status" ? (
                      row[cell.accessor] == "active" ? (
                        <Button
                          color="success"
                          className="btn-rounded  mb-1 me-1"
                          style={{
                            width: 80,
                            borderRadius: 5,
                            padding: 0,
                            cursor: "pointer",
                            zIndex: 9999,
                          }}
                          onClick={() => {
                            // setchangeStatusModal(true);
                            setStatusChangeItem(row);
                          }}
                        >
                          ACTIVE
                        </Button>
                      ) : (
                        <Button
                          color="danger"
                          className="btn-rounded  mb-1 me-1"
                          style={{
                            width: 80,
                            borderRadius: 5,
                            padding: 0,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            // setchangeStatusModal(true);
                            setStatusChangeItem(row);
                          }}
                        >
                          In Active
                        </Button>
                      )
                    ) : cell.accessor === "IsActive" ? (
                      row[cell.accessor] == true ? (
                        <Button
                          color="success"
                          className="btn-rounded  mb-1 me-1"
                          style={{
                            width: 80,
                            borderRadius: 5,
                            padding: 0,
                            cursor: "pointer",
                            zIndex: 9999,
                          }}
                          onClick={() => {
                            // setchangeStatusModal(true);
                            setStatusChangeItem(row);
                          }}
                        >
                          ACTIVE
                        </Button>
                      ) : (
                        <Button
                          color="danger"
                          className="btn-rounded  mb-1 me-1"
                          style={{
                            width: 80,
                            borderRadius: 5,
                            padding: 0,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            // setchangeStatusModal(true);
                            setStatusChangeItem(row);
                          }}
                        >
                          In Active
                        </Button>
                      )
                    ) : cell.accessor === "Full_Name" ? (
                      <span style={{ textAlign: "center" }}>
                        <img
                          className="rounded-circle header-profile-user"
                          src={user1}
                          alt="Header Avatar"
                          style={{ marginRight: 5 }}
                        />
                        {row[cell.accessor]}
                      </span>
                    ) : cell.accessor === "CreatedOn" ? (
                      row[cell.accessor] !== null &&
                      row[cell.accessor] !== "" &&
                      row[cell.accessor] !== undefined ? (
                        Moment(row[cell.accessor]).format(
                          "MMM DD, YYYY hh:mm A"
                        )
                      ) : (
                        ""
                      )
                    ) : cell.accessor === "ModifiedOn" ? (
                      row[cell.accessor] !== null &&
                      row[cell.accessor] !== "" &&
                      row[cell.accessor] !== undefined ? (
                        Moment(row[cell.accessor]).format("MMM DD, YYYY")
                      ) : (
                        ""
                      )
                    ) : cell.accessor === "orderDateTime" ? (
                      row[cell.accessor] !== null &&
                      row[cell.accessor] !== "" &&
                      row[cell.accessor] !== undefined ? (
                        Moment(row[cell.accessor]).format(
                          "MMM DD, YYYY - HH:mm A"
                        )
                      ) : (
                        ""
                      )
                    ) : cell.accessor === "indexing" ? (
                      index1 + 1 + page * rowsPerPage
                    ) : cell.accessor === "user_group_id" ? (
                      row[cell.accessor] && row[cell.accessor].name ? (
                        <p>{row[cell.accessor].name}</p>
                      ) : (
                        ""
                      )
                    ) : cell.accessor === "user_group_id" ? (
                      row[cell.accessor] && row[cell.accessor].name ? (
                        <p>{row[cell.accessor].name}</p>
                      ) : (
                        ""
                      )
                    ) : cell.accessor === "imageURL" ? (
                      row[cell.accessor].map((item, index) => {
                        return (
                          <a
                            key={index}
                            rel="noreferrer"
                            href={item}
                            target="_blank"
                          >
                            <img
                              className=" header-profile-user"
                              src={item}
                              alt="img"
                              style={{
                                margin: 3,
                                border: "1px solid black",
                              }}
                            />
                          </a>
                        );
                      })
                    ) : cell.accessor === "declareWinner" ? (
                      <Button
                        type="button"
                        // color="success"
                        style={{
                          backgroundColor: "#004a8d",
                        }}
                        className="btn-rounded  mb-2 me-2"
                        onClick={e => handleDeclareWinner(row)}
                      >
                        Declare Winner
                      </Button>
                    ) : cell.accessor === "SrNo" ? (
                      index1 + 1 + page * rowsPerPage
                    ) : cell.accessor === "paymentType" ? (
                      row["paymentType"] === "Delivered" ? (
                        "Delivery"
                      ) : (
                        "Donate"
                      )
                    ) : (
                      row[cell.accessor]
                    )}
                  </td>
                )
              );
            })}
          </tr>
        );
      });

  //-----------
  return (
    <Fragment>
      <Row className="mb-2">
        <Col lg="3" sm="4" xs="7">
          {/* <div
            className="position-relative"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <span className="bx bx-search-alt" style={{ fontSize: 30 }} />
            <input
              type="text"
              className="form-control"
              placeholder={"Search on page" + "..."}
              // onChange={e => handleSearch(e)}
              value={search}
              onChange={searchVal => {
                requestSearch(searchVal.target.value);
                setsearch(searchVal.target.value);
              }}
            />
          </div> */}
        </Col>

        {IsAddButton === true ? (
          <Col lg="9" sm="8" xs="5">
            <div className="text-sm-end" style={{ marginTop: 5 }}>
              <Button
                type="button"
                // color="success"
                style={{
                  backgroundColor: "#004a8d",
                }}
                className="btn-rounded  mb-2 me-2"
                onClick={addNewItem}
              >
                <i className="mdi mdi-plus me-1" />
                Add New
              </Button>
            </div>
          </Col>
        ) : (
          ""
        )}
      </Row>

      <div className="table-responsive react-table">
        <Table bordered hover className={className}>
          <TableHead className="table-light table-nowrap">
            <tr>
              {columns.map((headerGroup, index) =>
                headerGroup.hidden === false ? (
                  <th key={index}>
                    {/* <TableSortLabel 
                     active={orderBy === headerGroup.Header}
                     direction={orderBy === headerGroup.Header ? order : "asc"}
                   onClick={createSortHandler(headerGroup.Header)}
                    >
                  {headerGroup.Header}
                    </TableSortLabel> */}
                    {headerGroup.Header}
                  </th>
                ) : (
                  ""
                )
              )}
            </tr>
          </TableHead>

          <tbody>
            {DataList !== null && rows !== null ? (
              renderRows()
            ) : (
              <tr>
                <td></td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {initialData != null && TotalNoOfRows != null ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 30]}
          component="div"
          count={TotalNoOfRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : (
        ""
      )}
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => eventDel(DataToDelete)}
        onCloseClick={() => setdeleteModal(false)}
        isLoading={isLoading}
      />
      {/* <StatusChangeModal
        show={changeStatusModal}
        onConfirmClick={() => eventChangeStatus(StatusChangeItem)}
        onCloseClick={() => setchangeStatusModal(false)}
        isLoading={isLoading}
      /> */}
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
            zIndex: 999,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;
