import PropTypes from "prop-types";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Loading from "components/Loading";
import DialogPopup from "components/Common/DialogPopup";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  // Table,
} from "reactstrap";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { Link } from "react-router-dom";

//import action
import { getChartsData as onGetChartsData } from "../../store/actions";
import { setDashboardAttendanceId } from "store/actions";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import ActivityComp from "./ActivityComp";
import RequestsUser from "./RequestsUser";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

const Dashboard = props => {
  const [InnerWidth, setInnerWidth] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const theme = useSelector(state => state.Layout.layoutModeType);
  const user = useSelector(state => state.Login.User);
  const formOpen = useSelector(state => state.Dashboard.formOpen);
  const formValues = useSelector(state => state.Dashboard.formValues);
  const popupMsgValues = useSelector(state => state.Dashboard.popupMsgValues);
  const AttendaceID = useSelector(state => state.Dashboard.Attendance_Id);

  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //meta title
  document.title = "Admin - Dashboard";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            // breadcrumbItem={props.t("Dashboard")}
          />
          <Row>
            <Col xl="5" md="12" sm="12">
              <Col xl="12">
                <WelcomeComp />
              </Col>
            </Col>
          </Row>
        </Container>
      </div>

      {/* DialogPopup */}
      <DialogPopup branch={"Dashboard"} message={popupMsgValues} />

      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
            zIndex: 999,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
