import { get, post } from "../api_helper";

const Get_Home_Banner = body => {
  let url = "api/Get_Home_Banner";

  return get(url, body);
};

const Save_Home_Banner = body => {
  let url = "api/Save_Home_Banner";

  return post(url, body);
};

export default {
  Get_Home_Banner,
  Save_Home_Banner,
};
