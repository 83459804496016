import React from "react";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  List,
} from "@material-ui/core";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
// import datas from "dan-api/apps/connectionData";

function Connection(props) {
  const { classes, AcedamicData, EmployerData } = props;
  return (
    <>
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-start"
        direction="row"
        spacing={3}
      >
        {AcedamicData.length > 0 ? (
          <Grid item md={6} xs={12}>
            <Grid md={12}>
              <div style={{ display: "flex", color: "black" }}>
                <Typography
                  variant="h6"
                  component="h2"
                  className={classes.title}
                  style={{ marginTop: 12, fontSize: 22 }}
                >
                  ACADEMIC DETAILS
                </Typography>
              </div>
            </Grid>
            {AcedamicData.map((data, index) => {
              return (
                <List
                  dense
                  key={index}
                  className={classes.profileList}
                  style={{
                    backgroundColor: "white",
                    // border: "2px solid #004a8d",
                    boxShadow: "0px 0px 10px grey",
                    borderRadius: 10,
                    margin: "10px 0px",
                    padding: 10,
                  }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ marginTop: 10, color: "black" }}
                  >
                    <span style={{ fontWeight: "bold", color: "#2c2f42" }}>
                      {" "}
                      Degree Name :{" "}
                    </span>
                    {data.Degree_Name}
                    {/* {data.verified && <VerifiedUser />} */}
                  </Typography>
                  <Typography
                    gutterBottom
                    style={{ marginTop: -5, color: "black" }}
                  >
                    <span style={{ fontWeight: "bold", color: "#2c2f42" }}>
                      Grade :{" "}
                    </span>
                    {data.Grade}
                  </Typography>
                  <Typography
                    gutterBottom
                    style={{ marginTop: -5, color: "black" }}
                  >
                    <span style={{ fontWeight: "bold", color: "#2c2f42" }}>
                      GPA/Percentage :{" "}
                    </span>
                    {data.GPA}
                  </Typography>
                  <Typography
                    gutterBottom
                    style={{ marginTop: -5, color: "black" }}
                  >
                    <span style={{ fontWeight: "bold", color: "#2c2f42" }}>
                      Institute Name :{" "}
                    </span>
                    {data.Institute_Name}
                  </Typography>
                  <Typography
                    gutterBottom
                    style={{ marginTop: -5, color: "black" }}
                  >
                    <span style={{ fontWeight: "bold", color: "#2c2f42" }}>
                      City :{" "}
                    </span>
                    {data.City_Name}
                  </Typography>
                  <Typography
                    gutterBottom
                    style={{ marginTop: -5, color: "black" }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#2c2f42",
                      }}
                    >
                      Passing Year :{" "}
                    </span>
                    {data.Awarding_Year}
                  </Typography>
                </List>
              );
            })}
          </Grid>
        ) : (
          <Grid item md={6} xs={12}>
            <Grid md={12}>
              <div style={{ display: "flex", color: "black" }}>
                <Typography
                  variant="h6"
                  component="h2"
                  className={classes.title}
                  style={{ marginTop: 12, fontSize: 22 }}
                >
                  ACADEMIC DETAILS
                </Typography>
              </div>
            </Grid>
            <List
              dense
              className={classes.profileList}
              style={{
                backgroundColor: "white",
                // border: "2px solid #004a8d",
                boxShadow: "0px 0px 10px grey",
                borderRadius: 10,
                margin: "10px 0px",
                padding: 10,
              }}
            >
              <Typography
                // variant="h6"
                gutterBottom
                style={{ marginTop: 10, fontWeight: "bold", color: "#2c2f42" }}
              >
                Unfortunately there are no Academic Records available.
              </Typography>
            </List>
          </Grid>
        )}

        {EmployerData.length > 0 ? (
          <Grid item md={6} xs={12}>
            <Grid md={12}>
              <div style={{ display: "flex", color: "black" }}>
                <Typography
                  variant="h6"
                  component="h2"
                  className={classes.title}
                  style={{ marginTop: 12, fontSize: 22 }}
                >
                  PREVIOUS EMPLOYER RECORDS
                </Typography>
              </div>
            </Grid>
            {EmployerData.map((data, index) => {
              return (
                <List
                  dense
                  key={index}
                  className={classes.profileList}
                  style={{
                    backgroundColor: "white",
                    // border: "2px solid #004a8d",
                    boxShadow: "0px 0px 10px grey",
                    borderRadius: 10,
                    margin: "10px 0px",
                    padding: 10,
                  }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ marginTop: 10, color: "black" }}
                  >
                    <span style={{ fontWeight: "bold", color: "#2c2f42" }}>
                      {" "}
                      Employer Name :{" "}
                    </span>
                    {data.Employer_Name}
                    {/* {data.verified && <VerifiedUser />} */}
                  </Typography>
                  <Typography
                    gutterBottom
                    style={{ marginTop: -5, color: "black" }}
                  >
                    <span style={{ fontWeight: "bold", color: "#2c2f42" }}>
                      Start Date :{" "}
                    </span>
                    {moment(data.Start_Date).format("DD-MM-YYYY")}
                  </Typography>
                  <Typography
                    gutterBottom
                    style={{ marginTop: -5, color: "black" }}
                  >
                    <span style={{ fontWeight: "bold", color: "#2c2f42" }}>
                      End Date :{" "}
                    </span>
                    {moment(data.End_Date).format("DD-MM-YYYY")}
                  </Typography>
                  <Typography
                    gutterBottom
                    style={{ marginTop: -5, color: "black" }}
                  >
                    <span style={{ fontWeight: "bold", color: "#2c2f42" }}>
                      Job Description :{" "}
                    </span>
                    {data.Job_Description}
                  </Typography>
                </List>
              );
            })}
          </Grid>
        ) : (
          <Grid item md={6} xs={12}>
            <Grid md={12}>
              <div style={{ display: "flex", color: "black" }}>
                <Typography
                  variant="h6"
                  component="h2"
                  className={classes.title}
                  style={{ marginTop: 12, fontSize: 22 }}
                >
                  PREVIOUS EMPLOYER RECORDS
                </Typography>
              </div>
            </Grid>
            <List
              dense
              className={classes.profileList}
              style={{
                backgroundColor: "white",
                // border: "2px solid #004a8d",
                boxShadow: "0px 0px 10px grey",
                borderRadius: 10,
                margin: "10px 0px",
                padding: 10,
              }}
            >
              <Typography
                // variant="h6"
                gutterBottom
                style={{ marginTop: 10, fontWeight: "bold", color: "#2c2f42" }}
              >
                Unfortunately there are no Employer Records available.
              </Typography>
            </List>
          </Grid>
        )}
      </Grid>
    </>
  );
}

Connection.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles()(Connection);
