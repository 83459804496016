import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
//redux
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";

//auth.service
import authservice from "../../../helpers/auth.service";
import { logout } from "../../../store/actions";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const Login = useSelector(state => state.Login);
  const user = useSelector(state => state.Login.User);
  const [username, setusername] = useState("Admin");

  const handleLogout = () => {
    authservice.logout().then(
      data => {
        dispatch(logout());
        // window.location.href = "/login";
      },
      error => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    // if (localStorage.getItem("authUser")) {
    //   if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
    //     const obj = JSON.parse(localStorage.getItem("authUser"));
    //     setusername(obj.displayName);
    //   } else if (
    //     process.env.REACT_APP_DEFAULTAUTH === "fake" ||
    //     process.env.REACT_APP_DEFAULTAUTH === "jwt"
    //   ) {
    //     const obj = JSON.parse(localStorage.getItem("authUser"));
    //     setusername(obj.username);
    //   }
    // }
    if (Login.User !== null && Login.User !== undefined) {
      setusername(Login.User.firstName);
    } else {
      localStorage.clear();
      window.location.href = "/login";
    }
  }, [Login]);

  const gotoProfile = () => {
    navigate("/profile");
  };
  const gotoChangePassword = () => {
    navigate("/change_password");
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          {/* <img
              className="rounded-circle header-profile-user"
              src={
                user.User_Photo_Path !== null && user.User_Photo_Path !== ""
                  ? user.User_Photo_Path
                  : user1
              }
              alt="Header Avatar"
            /> */}
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <DropdownItem
            tag="Profile"
            onClick={gotoProfile}
            style={{ cursor: "pointer" }}
          >
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}
          </DropdownItem>
          <DropdownItem
            tag="Profile"
            onClick={gotoChangePassword}
            style={{ cursor: "pointer" }}
          >
            <i className="bx bx-key font-size-16 align-middle me-1" />
            {props.t("Change Password")}
          </DropdownItem> 
          <div className="dropdown-divider" /> */}
          <Link className="dropdown-item" onClick={handleLogout}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
