import React, { useState } from "react";
import { Container } from "reactstrap";
import Loading from "components/Loading";
import DialogPopup from "components/Common/DialogPopup";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import TableContainer from "components/Common/TableContainer";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setData, setPopupMessageValues } from "store/actions";

import {
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import _Tickets from "helpers/Tickets";
import _Campaigns from "helpers/Campaigns";

//i18n
import { withTranslation } from "react-i18next";
const Tickets = props => {
  //meta title
  document.title = "Winzco - Tickets";
  const anchorTable = _Tickets.anchorTable;
  const [initialList, setInitialList] = useState(null);
  const [Campaigns, setCampaigns] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const branch = "crudTbTickets";

  const dispatch = useDispatch();
  const initData = useSelector(state => state.crudTbTickets.dataTable);
  const popupMessage = useSelector(state => state.crudTbTickets.popupMessage);

  const initialData = async () => {
    try {
      setisLoading(true);
      const response = await _Campaigns.Get_Campaigns_Dropdown();
      if (response.IsSuccess === true && response.Status_Code === 200) {
        setCampaigns(response.Data);
        dispatch(setPopupMessageValues(response.message, branch));
        dispatch(setPopupMessageValues(null, branch));
        setisLoading(false);
      } else {
        setCampaigns(null);
        dispatch(setPopupMessageValues(response.message, branch));
        setisLoading(false);
      }
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
      setisLoading(false);
    }
  };

  React.useEffect(() => {
    initialData();
  }, []);

  React.useEffect(() => {
    dispatch(setPopupMessageValues("", branch));
    setInitialList(initData);
    dispatch(setPopupMessageValues(null, branch));
  }, [initData]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      campaignId: "",
    },
    validationSchema: Yup.object({
      campaignId: Yup.string().required("Please select a campaign"),
    }),
    onSubmit: async values => {
      setisLoading(true);

      const response = await _Tickets.Get_Users_By_CampaignID(
        values.campaignId
      );
      if (response.IsSuccess === true && response.Status_Code === 200) {
        let data = response.Data;
        dispatch(setData(data, branch));
        setInitialList(data);
        setisLoading(false);
        dispatch(setPopupMessageValues(response.message, branch));
      } else {
        console.log(response.message);
        setisLoading(false);
        dispatch(setPopupMessageValues(response.message, branch));
      }
    },
  });

  const renderCampaigns = () =>
    Campaigns.map((item, index) => (
      <option key={index} value={item._id}>
        {item.name}
      </option>
    ));

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("")}
            breadcrumbItem={props.t("Tickets")}
          />

          <Form
            onSubmit={e => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Col className="col-4">
                <div className="mb-3">
                  <Label className="form-label">
                    <span style={{ color: "red" }}>* </span>Campaign
                  </Label>
                  <Input
                    type="select"
                    name="campaignId"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    required
                    value={validation.values.campaignId || ""}
                    invalid={
                      validation.touched.campaignId &&
                      validation.errors.campaignId
                        ? true
                        : false
                    }
                  >
                    <option key={1} value={0}>
                      Select
                    </option>
                    {Campaigns == null || Campaigns == undefined
                      ? ""
                      : renderCampaigns()}
                  </Input>
                  {validation.touched.campaignId &&
                  validation.errors.campaignId ? (
                    <FormFeedback type="invalid">
                      {validation.errors.campaignId}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col className="col-4 text-start">
                <button
                  type="submit"
                  className="btn btn-success"
                  id="btn-save-event"
                >
                  Submit
                </button>
              </Col>
            </Row>
          </Form>
          {/* <Table columns={columns} data={data} /> */}
          {anchorTable == null && initialList == null ? (
            ""
          ) : (
            <TableContainer
              columns={anchorTable}
              initialData={initialList}
              customPageSize={10}
              className="custom-header-css"
              branch={branch}
            />
          )}
        </Container>
      </div>

      {/* DialogPopup */}
      <DialogPopup branch={branch} message={popupMessage} />

      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default withTranslation()(Tickets);
