import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import withRouter from "components/Common/withRouter";

//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";

import { Link } from "react-router-dom";

// import logo from "../../assets/images/logo.svg";
import logoDark from "../../assets/images/logo.svg";
// import { useEffect } from "react";

const Sidebar = props => {
  const theme = useSelector(state => state.Layout.layoutModeType);
  return (
    <React.Fragment>
      <div
        className="vertical-menu"
        style={{
          backgroundColor: theme == "light" ? "white" : "#1D1D2B",
          boxShadow:
            theme == "light" ? "2px 5px 5px #DDDDDD" : "2px 5px 5px black",
        }}
      >
        <div
          className="navbar-brand-box"
          style={{
            backgroundColor: theme == "light" ? "white" : "#1D1D2B",
          }}
        >
          {theme == "light" ? (
            <Link to="/dashboard" className="logo logo-light">
              <span className="logo-sm">
                <img src={logoDark} alt="" height="40" />
              </span>
              <span className="logo-lg">
                <img src={logoDark} alt="" height="80" />
              </span>
            </Link>
          ) : (
            <Link to="/dashboard" className="logo logo-light">
              <span className="logo-sm">
                <img src={logoDark} alt="" height="40" />
              </span>
              <span className="logo-lg">
                <img src={logoDark} alt="" height="50" />
              </span>
            </Link>
          )}
        </div>
        <div
          data-simplebar
          className="h-100"
          // style={{ backgroundColor: theme == "light" ? "white" : "#1D1D2B" }}
        >
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  };
};
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)));
