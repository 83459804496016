import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Moment from "moment";
import PropTypes from "prop-types";
import authService from "helpers/auth.service";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import _validation from "helpers/validation";
import { RotatingLines } from "react-loader-spinner";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import Loading from "components/Loading";
import { setDashboardFormValues, setDashboardPopupValues } from "store/actions";
//auth.service
import authservice from "../../helpers/auth.service";
import { logout } from "../../store/actions";

import {
  Row,
  Col,
  Input,
  FormGroup,
  Form,
  FormFeedback,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

const floatingForm = props => {
  const { branch, classes } = props;

  const [isLoading, setisLoading] = useState(false);
  const [viewCurrent, setviewCurrent] = useState(false);
  const [viewNew, setviewNew] = useState(false);

  const user = useSelector(state => state.Login.User);

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Current_Password: "",
      New_Password: "",
    },
    validationSchema: Yup.object({
      Current_Password: Yup.string().required("Please Enter Current Password."),
      New_Password: Yup.string().required("Please Enter New Password."),
    }),
    onSubmit: async values => {
      setisLoading(true);
      let body = {
        id: user.Employee_Id,
        Current_Password: values.Current_Password,
        New_Password: values.New_Password,
      };
      const response = await authService.Password_Change(body);
      if (response.IsSuccess === true && response.Status_Code === 200) {
        dispatch(setDashboardFormValues({ success: true }));
        dispatch(
          setDashboardPopupValues(
            "Password has been changed succefully. You have been logged out."
          )
        );
        setisLoading(false);

        setTimeout(() => {
          handleLogout();
        }, 2000);
      } else {
        console.log(response.Status_Message);
        setisLoading(false);
        dispatch(setDashboardFormValues({ success: false }));
        dispatch(setDashboardPopupValues(response.Status_Message));
        setisLoading(false);
      }
    },
  });

  const showCurrentPassword = () => {
    setviewCurrent(!viewCurrent);
  };

  const handleLogout = () => {
    authservice.logout().then(
      data => {
        dispatch(logout());
        // window.location.href = "/login";
      },
      error => {
        console.log(error);
      }
    );
  };

  const showNewPassword = () => {
    setviewNew(!viewNew);
  };

  return (
    <>
      <Grid container>
        <Grid item md={4} xs={12} style={{ margin: "auto" }}>
          {/* Password Change Card */}
          <Form
            onSubmit={e => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Grid
              container
              //   className={classes.colList}
              style={{
                backgroundColor: "#ffffff",
                boxShadow: "0px 0px 10px grey",
                borderRadius: 10,
                color: "grey",
                marginTop: 10,
              }}
            >
              <Grid item md={12}>
                <div style={{ display: "flex" }}>
                  <Avatar style={{ margin: 15, backgroundColor: "#004a8d" }}>
                    {/* <Person /> */}
                    <i
                      className="fa fa-key"
                      style={{ fontSize: 20 }}
                      aria-hidden="true"
                    ></i>
                  </Avatar>
                  <Typography
                    variant="h6"
                    component="h2"
                    // className={classes.title}
                    style={{ marginTop: 12, fontSize: 25 }}
                  >
                    Change Password
                  </Typography>
                </div>
              </Grid>
              <Grid
                style={{
                  backgroundColor: "#f0f0f0",
                  borderRadius: 10,
                  display: "grid",
                  margin: "0px 10px 10px 10px",
                  paddingTop: 10,
                  paddingLeft: 15,
                  paddingRight: 15,
                }}
                item
                md={12}
                sm={12}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">
                        <span style={{ color: "red" }}>* </span>Current Password
                      </Label>
                      <div
                        style={{
                          position: "relative",
                          marginBottom: 10,
                          display: "flex",
                        }}
                      >
                        <Input
                          name="Current_Password"
                          type={viewCurrent ? "text" : "password"}
                          maxLength={255}
                          // onKeyPressCapture={e => handleValidate(e)}
                          placeholder="Enter current password"
                          // value={isEdit ? InitialFormData.Current_Password : ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.Current_Password || ""}
                          invalid={
                            validation.touched.Current_Password &&
                            validation.errors.Current_Password
                              ? true
                              : false
                          }
                        />
                        <button
                          style={{
                            margin: 0,
                            padding: 0,
                            width: 40,
                            minHeight: "100%",
                            fontFamily: "Open Sans",
                            display: "flex",
                            border: "none",
                            justifyContent: "center",
                            backgroundColor: "#004a8d",
                            alignItems: "center",
                            color: " #fff",
                            fontSize: 14,
                          }}
                          onClick={showCurrentPassword}
                          type="button"
                        >
                          <i
                            className={
                              viewCurrent ? "fa fa-eye-slash" : "fa fa-eye"
                            }
                            aria-hidden="true"
                          ></i>
                        </button>
                      </div>
                      {validation.touched.Current_Password &&
                      validation.errors.Current_Password ? (
                        <FormFeedback type="invalid">
                          {validation.errors.Current_Password}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">
                        <span style={{ color: "red" }}>* </span>New Password
                      </Label>
                      <div
                        style={{
                          position: "relative",
                          marginBottom: 10,
                          display: "flex",
                        }}
                      >
                        <Input
                          name="New_Password"
                          type={viewNew ? "text" : "password"}
                          maxLength={255}
                          // onKeyPressCapture={e => handleValidate(e)}
                          placeholder="Enter new password"
                          // value={isEdit ? InitialFormData.New_Password : ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.New_Password || ""}
                          invalid={
                            validation.touched.New_Password &&
                            validation.errors.New_Password
                              ? true
                              : false
                          }
                        />
                        <button
                          style={{
                            margin: 0,
                            padding: 0,
                            width: 40,
                            minHeight: "100%",
                            fontFamily: "Open Sans",
                            display: "flex",
                            border: "none",
                            justifyContent: "center",
                            backgroundColor: "#004a8d",
                            alignItems: "center",
                            color: " #fff",
                            fontSize: 14,
                          }}
                          onClick={showNewPassword}
                          type="button"
                        >
                          <i
                            className={
                              viewNew ? "fa fa-eye-slash" : "fa fa-eye"
                            }
                            aria-hidden="true"
                          ></i>
                        </button>
                      </div>
                      {validation.touched.New_Password &&
                      validation.errors.New_Password ? (
                        <FormFeedback type="invalid">
                          {validation.errors.New_Password}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-9"></Col>
                  <Col lg="3">
                    <div
                      className="mb-3"
                      style={{ display: "flex", height: 30 }}
                    >
                      <Button
                        type="submit"
                        style={{
                          position: "absolute",
                          right: 10,
                          backgroundColor: "#004a8d",
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>

      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

floatingForm.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default floatingForm;
