import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  CHANGE_PROFILE_PICTURE,
  SET_POP_MESSAGE_VALUES,
} from "./actionTypes";

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  };
};

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  };
};

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  };
};

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  };
};

export const changeProfilePicture = File => {
  return {
    type: CHANGE_PROFILE_PICTURE,
    payload: File,
  };
};

export const setPopMessage = value => {
  return {
    type: SET_POP_MESSAGE_VALUES,
    payload: value,
  };
};
