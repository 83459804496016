import { get, post } from "../api_helper";

const Get_MultiCurrency = body => {
  let url = "api/Get_MultiCurrency";

  return get(url, body);
};

const Get_Companies_dropdown = body => {
  let url = "Campaigns/Get_Companies_dropdown";

  return get(url, body);
};

const Save_MultiCurrency = body => {
  let url = "api/Save_MultiCurrency";
  return post(url, body);
};

const Edit_MultiCurrency = body => {
  let url = "api/Edit_MultiCurrency";
  return post(url, body);
};

const Delete_MultiCurrency = body => {
  let url = "/api/Delete_MultiCurrency";
  return post(url, body);
};
const anchorTable = [
  {
    Header: "S.No..",
    accessor: "indexing",
    hidden: false,
    width: 50,
  },
  {
    Header: "Name",
    accessor: "name",
    hidden: false,
  },
  {
    Header: "Code",
    accessor: "code",
    hidden: false,
  },
  {
    Header: "Exchange Rate",
    accessor: "exchangeRate",
    hidden: false,
  },
  {
    Header: "Decimal Places",
    accessor: "decimalPoints",
    hidden: false,
  },
  {
    Header: "IsActive",
    accessor: "IsActive",
    hidden: false,
    width: 100,
  },
  {
    Header: "Created By",
    accessor: "CreatedBy",
    hidden: false,
    width: 100,
  },
  {
    Header: "Created On",
    accessor: "CreatedOn",
    hidden: false,
    width: 120,
  },
  {
    Header: "Modified On",
    accessor: "ModifiedOn",
    hidden: false,
    width: 120,
  },
  {
    Header: "Action",
    accessor: "Action",
    edit: true,
    delete: true,
    view: false,
    add: true,
    hidden: false,
    width: 100,
  },
];

export default {
  Get_MultiCurrency,
  Get_Companies_dropdown,
  Save_MultiCurrency,
  Edit_MultiCurrency,
  Delete_MultiCurrency,
  anchorTable,
};
