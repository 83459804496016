/** Get Dashboard Chart data */
export const API_SUCCESS = "API_SUCCESS";
export const API_FAIL = "API_FAIL";
export const GET_CHARTS_DATA = "GET_CHARTS_DATA";
export const OPEN_LEAVE_APPROVAL_FORM = "OPEN_LEAVE_APPROVAL_FORM";
export const SET_FORM_VALUES = "SET_FORM_VALUES";
export const SET_DASHBOARD_POPUP_VALUES = "SET_DASHBOARD_POPUP_VALUES";
export const SET_DASHBOARD_IS_COPIED = "SET_DASHBOARD_IS_COPIED";
export const SET_LEAVE_APPROVAL_STATUS_VALUES =
  "SET_LEAVE_APPROVAL_STATUS_VALUES";
export const SET_ATTENDANCE_ID = "SET_ATTENDANCE_ID";
