import { get, post } from "../api_helper";

const Get_Prize = body => {
  let url = "api/Get_Prize";

  return get(url, body);
};

const Get_Prize_Dropdown = body => {
  let url = "api/Get_Prize_Dropdown";

  return get(url, body);
};

const Save_Prize = body => {
  let url = "api/Save_Prize";
  return post(url, body);
};

const Edit_Prize = body => {
  let url = "api/Edit_Prize";
  return post(url, body);
};

const Delete_Prize = body => {
  let url = "api/Delete_Prize";
  return post(url, body);
};
const anchorTable = [
  {
    Header: "S.No..",
    accessor: "indexing",
    hidden: false,
    width: 50,
  },
  {
    Header: "Name",
    accessor: "name",
    hidden: false,
  },
  {
    Header: "Description",
    accessor: "description",
    hidden: false,
  },
  {
    Header: "Amount",
    accessor: "amount",
    hidden: true,
  },
  {
    Header: "IsActive",
    accessor: "IsActive",
    hidden: false,
    width: 100,
  },
  {
    Header: "Created By",
    accessor: "CreatedBy",
    hidden: false,
    width: 100,
  },
  {
    Header: "Created On",
    accessor: "CreatedOn",
    hidden: false,
    width: 120,
  },
  {
    Header: "Modified On",
    accessor: "ModifiedOn",
    hidden: false,
    width: 120,
  },
  {
    Header: "Action",
    accessor: "Action",
    edit: true,
    delete: true,
    view: false,
    add: true,
    hidden: false,
    width: 100,
  },
];

export default {
  Get_Prize,
  Get_Prize_Dropdown,
  Save_Prize,
  Edit_Prize,
  Delete_Prize,
  anchorTable,
};
