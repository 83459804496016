import React, { useState } from "react";
import { Container } from "reactstrap";
import Loading from "components/Loading";
import DialogPopup from "components/Common/DialogPopup";
import FloatingForm from "components/Forms/Users/form";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import TableContainer from "components/Common/TableContainer";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setData, setPopupMessageValues } from "store/actions";

import _Users from "helpers/Users";

//i18n
import { withTranslation } from "react-i18next";
const Users = props => {
  //meta title
  document.title = "Winzco - Users";
  const anchorTable = _Users.anchorTable;
  const [initialList, setInitialList] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const branch = "crudTbUsers";

  const dispatch = useDispatch();
  const initData = useSelector(state => state.crudTbUsers.dataTable);
  const formOpen = useSelector(state => state.crudTbUsers.formOpen);
  const popupMessage = useSelector(state => state.crudTbUsers.popupMessage);

  const initialData = async () => {
    try {
      setisLoading(true);
      const response = await _Users.Get_Users();
      if (response.IsSuccess === true && response.Status_Code === 200) {
        console.log(response);
        setInitialList(response.Data);
        dispatch(setData(response.Data, branch));
        dispatch(setPopupMessageValues(response.message, branch));
        dispatch(setPopupMessageValues(null, branch));
        setisLoading(false);
      } else {
        setInitialList([]);
        dispatch(setPopupMessageValues(response.message, branch));
        setisLoading(false);
      }
    } catch (error) {
      dispatch(setPopupMessageValues("Opps! Server is down.", branch));
      setisLoading(false);
    }
  };

  React.useEffect(() => {
    initialData();
  }, []);

  React.useEffect(() => {
    dispatch(setPopupMessageValues("", branch));
    setInitialList(initData);
    dispatch(setPopupMessageValues(null, branch));
  }, [initData]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={props.t("")} breadcrumbItem={props.t("Users")} />
          {/* <Table columns={columns} data={data} /> */}
          {anchorTable == null && initialList == null ? (
            ""
          ) : (
            <TableContainer
              columns={anchorTable}
              initialData={initialList}
              customPageSize={10}
              className="custom-header-css"
              branch={branch}
            />
          )}
        </Container>
      </div>

      {/* DialogPopup */}
      <DialogPopup branch={branch} message={popupMessage} />

      {/* MODAL */}
      {formOpen !== undefined && formOpen !== null ? (
        formOpen === true ? (
          <FloatingForm branch={branch} />
        ) : (
          ""
        )
      ) : (
        ""
      )}
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
            backgroundColor: "#333",
            opacity: 0.8,
          }}
          pointerEvents={"none"}
        >
          <Loading />
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default withTranslation()(Users);
