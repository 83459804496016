import PropTypes from "prop-types";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Form,
  Input,
  FormFeedback,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import Loading from "components/Loading";
import DialogPopup from "components/Common/DialogPopup";
//authentication
import authservice from "../../helpers/auth.service";
import userService from "../../helpers/user.service";
import { login, loginUser, login_fail } from "../../store/actions";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

const Login = props => {
  //meta title
  document.title = "Winzco - Login";
  const [isLoading, setisLoading] = useState(false);
  const [IsPasswordVisible, setIsPasswordVisible] = useState(false);
  const { isLoggedIn, notifMsg } = useSelector(state => state.Login);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async values => {
      setisLoading(true);

      const response = await authservice.login(values);
      if (response.Status_Code === 200) {
        const now = new Date();
        userService.Set_User(response.Data, now.getTime() + 86400000);
        const data = {
          ...response.Data,
          notification: [],
        };
        setTimeout(() => {
          dispatch(login(data));
          setisLoading(false);
        }, 1000);
      } else if (response.Status_Code === 401) {
        setisLoading(false);
        dispatch(login_fail(response.Status_Message));
      } else {
        console.log(response.message);
        dispatch(login_fail(response.message));
        setisLoading(false);
      }
    },
  });

  if (isLoggedIn) {
    // window.location.href = "/dashboard";
    navigate("/dashboard");
  }

  const showPassword = () => {
    setIsPasswordVisible(!IsPasswordVisible);
  };

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }));

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <br />
                  <br />
                  <div
                    style={{
                      textAlign: "center",
                      letterSpacing: 2,
                      margin: 10,
                    }}
                  >
                    <h5 style={{ fontWeight: "bold" }}>ADMIN LOGIN</h5>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {/* {error ? <Alert color="danger">{error}</Alert> : null} */}

                      <div className="mb-3">
                        <div
                          style={{
                            position: "relative",
                            marginBottom: 10,
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              left: "30px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              zIndex: 999,
                            }}
                          >
                            <i className="fa fa-user" aria-hidden="true"></i>
                          </span>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                            style={{
                              position: "relative",
                              zIndex: 1,
                              paddingLeft: 80,
                            }}
                          />
                        </div>
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <div
                          style={{
                            position: "relative",
                            marginBottom: 10,
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              left: "30px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              zIndex: 999,
                            }}
                          >
                            <i className="fa fa-lock" aria-hidden="true"></i>
                          </span>
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type={IsPasswordVisible ? "text" : "password"}
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                            style={{
                              position: "relative",
                              zIndex: 1,
                              paddingLeft: 80,
                            }}
                          />
                        </div>
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      {/* <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div> */}
                      <div
                        className="mt-4 text-center"
                        style={{
                          position: "absolute",
                          right: 27,
                          top: "50%",
                        }}
                      >
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot password?
                        </Link>
                      </div>
                      <br />
                      <br />
                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-block"
                          type="submit"
                          style={{
                            width: 100,
                            margin: "auto",
                            backgroundColor: "#004a8d",
                            color: "white",
                          }}
                        >
                          Log In
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <DialogPopup branch={"Login"} message={notifMsg} />
        {isLoading ? (
          <div
            style={{
              position: "fixed",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              justifyContent: "center",
              backgroundColor: "#333",
              opacity: 0.8,
              zIndex: 999,
            }}
            pointerEvents={"none"}
          >
            <Loading />
          </div>
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
