import Encryption from "../common/Encryption";
import { del, get, post, put } from "./api_helper";
import axios from "axios";
// const API_URL = "https://geolocation-db.com/json/"; //changed on 10-MAY-2023 coz it wasn't working
const API_URL = "https://api.ipify.org?format=json";

const login = async body => {
  const url = "api/signin";
  return post(url, body);
};

const Reset_Password = body => {
  const url = "api/UserPassword/Reset_Password";
  const params = {
    method: "POST",
    Content_Type: "application/json",
    formBody: body,
  };

  return post(params, url);
};

const Change_Password_via_link = body => {
  const url = "api/UserPassword/Change_Password_via_link";
  const params = {
    method: "POST",
    Content_Type: "application/json",
    formBody: body,
  };

  return post(params, url);
};

const Password_link_validation = key => {
  const body = JSON.stringify({
    Key: key,
  });

  const url = "api/UserPassword/Password_link_validation";
  const params = {
    method: "POST",
    Content_Type: "application/json",
    formBody: body,
  };

  return post(params, url);
};

const Password_Change = data => {
  const url = `Change_Password?User_Id=${data.id}&Current_Password=${data.Current_Password}&New_Password=${data.New_Password}`;
  // const params = {
  //   method: "POST",
  //   Content_Type: "application/json",
  //   formBody: body,
  // };

  return post(url);
};

const logout = async () => {
  // await localStorage.removeItem("User", "I18N_LANGUAGE", "i18nextLng");
  await localStorage.clear();
  return "Logout";
};

export default {
  logout,
  login,
  Reset_Password,
  Change_Password_via_link,
  Password_link_validation,
  Password_Change,
};
