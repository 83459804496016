import axios from "axios";
import userService from "./user.service";

//apply base url for axios
// const API_URL = "http://localhost:8000/";
// const API_URL = "https://winzco.com/";
const API_URL = "http://141.98.153.110:8000/";

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
);

//------------------------------
export async function get(url, config = {}) {
  //pass new generated access token here
  let Authorization = userService.Get_Token();
  Authorization = "Bearer " + Authorization;
  axiosApi.defaults.headers.common["Authorization"] = Authorization;

  return await axiosApi
    .get(API_URL + url, { ...config })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      return err;
    });
}

export async function post(url, data, config = {}) {
  //pass new generated access token here
  let Authorization = userService.Get_Token();
  Authorization = "Bearer " + Authorization;
  axiosApi.defaults.headers.common["Authorization"] = Authorization;
  return await axiosApi
    .post(API_URL + url, { ...data }, { ...config })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      return err;
    });
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(API_URL + url, { ...data }, { ...config })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      return err;
    });
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      return err;
    });
}
