import {
  API_SUCCESS,
  API_FAIL,
  GET_CHARTS_DATA,
  OPEN_LEAVE_APPROVAL_FORM,
  SET_FORM_VALUES,
  SET_DASHBOARD_POPUP_VALUES,
  SET_LEAVE_APPROVAL_STATUS_VALUES,
  SET_DASHBOARD_IS_COPIED,
  SET_ATTENDANCE_ID,
} from "./actionTypes";

export const apiSuccess = (actionType, data) => ({
  type: API_SUCCESS,
  payload: { actionType, data },
});

export const apiFail = (actionType, error) => ({
  type: API_FAIL,
  payload: { actionType, error },
});

// charts data
export const getChartsData = periodType => ({
  type: GET_CHARTS_DATA,
  payload: periodType,
});

// Leave Approval form
export const openLeaveApprovalForm = data => ({
  type: OPEN_LEAVE_APPROVAL_FORM,
  payload: data,
});
export const setDashboardFormValues = data => ({
  type: SET_FORM_VALUES,
  payload: data,
});
export const setDashboardPopupValues = data => ({
  type: SET_DASHBOARD_POPUP_VALUES,
  payload: data,
});
export const setLeaveApprovalStatusValues = data => ({
  type: SET_LEAVE_APPROVAL_STATUS_VALUES,
  payload: data,
});
export const setDashboardIsCopied = data => ({
  type: SET_DASHBOARD_IS_COPIED,
  payload: data,
});
export const setDashboardAttendanceId = data => ({
  type: SET_ATTENDANCE_ID,
  payload: data,
});
