import React from "react";
import { Navigate } from "react-router-dom";

// Authentication related pages
import Login__ from "../pages/Authentication/Login__";
import LockScreen from "../pages/Authentication/LockScreen";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import UserProfile from "../pages/Authentication/user-profile";
import ChangePassword from "pages/ChangePassword";

import Users from "pages/Users";
import Campaigns from "pages/Campaigns";
import CampaignCategories from "pages/CampaignCategories";
import Products from "pages/Products";
import Prize from "pages/Prize";
import Draws from "pages/Draws";
import Winners from "pages/Winners";
import Coupons from "pages/Coupons";
import PushNotifications from "pages/PushNotifications";
import MultiCurrency from "pages/MultiCurrency";
import ShippingCharges from "pages/ShippingCharges";
import Orders from "pages/Orders";
import HomeBanner from "pages/HomeBanner";
import Tickets from "pages/Tickets";
import TermsAndConditions from "pages/TermsAndConditions";

const authProtectedRoutes = [
  { path: "/", component: <Dashboard /> },
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/profile", component: <UserProfile /> },
  { path: "/change_password", component: <ChangePassword /> },

  // Pages
  { path: "/users", component: <Users /> },
  { path: "/campaigns", component: <Campaigns /> },
  { path: "/campagi_categories", component: <CampaignCategories /> },
  { path: "/products", component: <Products /> },
  { path: "/prize", component: <Prize /> },
  { path: "/draws", component: <Draws /> },
  { path: "/winners", component: <Winners /> },
  { path: "/coupons", component: <Coupons /> },
  { path: "/push_notifications", component: <PushNotifications /> },
  { path: "/multi_currency", component: <MultiCurrency /> },
  { path: "/shipping_charges", component: <ShippingCharges /> },
  { path: "/orders", component: <Orders /> },
  { path: "/home_banner", component: <HomeBanner /> },
  { path: "/tickets", component: <Tickets /> },
  { path: "/terms-and-conditions", component: <TermsAndConditions /> },
];

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/session_timeout", component: <LockScreen /> },
  { path: "/login", component: <Login__ /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
];

export { authProtectedRoutes, publicRoutes };
