import _CampaignCategories from "helpers/CampaignCategories";
import _Campaign from "helpers/Campaigns";
import _Products from "helpers/Products";
import _Prize from "helpers/Prize";
import _Winners from "helpers/Winners";
import _Coupons from "helpers/Coupons";
import _PushNotifications from "helpers/PushNotifications";
import _MultiCurrency from "helpers/MultiCurrency";
import _ShippingCharges from "helpers/ShippingCharges";
let User_Id;

const delete_CampaignCategories = async (data, user) => {
  try {
    const body = {
      _id: data._id,
      IsActive: false,
      IsDelete: true,
      User: user,
    };

    const responseee = await _CampaignCategories.Delete_CampaignCategory(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_Campaign = async (data, user) => {
  try {
    const body = {
      _id: data._id,
      IsActive: false,
      IsDelete: true,
      User: user,
    };

    const responseee = await _Campaign.Delete_Campaigns(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_Products = async (data, user) => {
  try {
    const body = {
      _id: data._id,
      IsActive: false,
      IsDelete: true,
      User: user,
    };

    const responseee = await _Products.Delete_Product(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_Prize = async (data, user) => {
  try {
    const body = {
      _id: data._id,
      IsActive: false,
      IsDelete: true,
      User: user,
    };

    const responseee = await _Prize.Delete_Prize(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_Winners = async (data, user) => {
  try {
    const body = {
      _id: data._id,
      IsActive: false,
      IsDelete: true,
      User: user,
    };

    const responseee = await _Winners.Delete_Winner(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_Coupons = async (data, user) => {
  try {
    const body = {
      _id: data._id,
      IsActive: false,
      IsDelete: true,
      User: user,
    };

    const responseee = await _Coupons.Delete_Coupon(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_PushNotifications = async (data, user) => {
  try {
    const body = {
      _id: data._id,
      IsActive: false,
      IsDelete: true,
      User: user,
    };

    const responseee = await _PushNotifications.Delete_PushNotification(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_MultiCurrency = async (data, user) => {
  try {
    const body = {
      _id: data._id,
      IsActive: false,
      IsDelete: true,
      User: user,
    };

    const responseee = await _MultiCurrency.Delete_MultiCurrency(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delete_ShippingCharges = async (data, user) => {
  try {
    const body = {
      _id: data._id,
      IsActive: false,
      IsDelete: true,
      User: user,
    };

    const responseee = await _ShippingCharges.Delete_ShippingCharge(body);
    return responseee;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const delAction = async (branch, item, user) => {
  let save_response = null;

  switch (branch) {
    case "crudTbCampaignCategories": {
      save_response = await delete_CampaignCategories(item, user);
      break;
    }
    case "crudTbCampaigns": {
      save_response = await delete_Campaign(item, user);
      break;
    }
    case "crudTbProducts": {
      save_response = await delete_Products(item, user);
      break;
    }
    case "crudTbPrize": {
      save_response = await delete_Prize(item, user);
      break;
    }
    case "crudTbWinners": {
      save_response = await delete_Winners(item, user);
      break;
    }
    case "crudTbCoupons": {
      save_response = await delete_Coupons(item, user);
      break;
    }
    case "crudTbPushNotifications": {
      save_response = await delete_PushNotifications(item, user);
      break;
    }
    case "crudTbMultiCurrency": {
      save_response = await delete_MultiCurrency(item, user);
      break;
    }
    case "crudTbShippingCharges": {
      save_response = await delete_ShippingCharges(item, user);
      break;
    }
    default:
      break;
  }

  return save_response;
};

export default {
  delAction,
};
