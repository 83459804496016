// validation functions
const maxDigitLength = max => event =>
  event && event.which.length > max
    ? `Must be ${max} digits or less`
    : undefined;
const minDigitLength = min => value =>
  value && value.length < min ? `Must be ${min} digits or more` : undefined;
const maxLength4 = maxDigitLength(4);
const email = event =>
  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
    String.fromCharCode(event.which)
  )
    ? event.preventDefault()
    : "";
const aol = event =>
  /.+@aol\.com/.test(String.fromCharCode(event.which))
    ? event.preventDefault()
    : "";
const wholeNumber = event =>
  !/^[0-9]\d*$/i.test(String.fromCharCode(event.which))
    ? event.preventDefault()
    : "";
const alphaNumeric = event =>
  !/^[a-z 0-9]+$/i.test(String.fromCharCode(event.which))
    ? event.preventDefault()
    : "";
const alphaNumeric2 = event =>
  !/^[a-z0-9&()/_ -]*$/i.test(String.fromCharCode(event.which))
    ? event.preventDefault()
    : "";
const alphaNumericPlusMinus = event =>
  !/^[a-z +-]*$/i.test(String.fromCharCode(event.which))
    ? event.preventDefault()
    : "";
const alphaNumericPlusMinusNoSpace = event =>
  !/^[a-z+-]*$/i.test(String.fromCharCode(event.which))
    ? event.preventDefault()
    : "";
const alphabetic = event =>
  !/^[a-z ]\d*$/i.test(String.fromCharCode(event.which))
    ? event.preventDefault()
    : "";
const Number = event =>
  /^(-{0,1}\d*\.{0,1}\d+)*$/i.test(String.fromCharCode(event.which)) == 0
    ? event.preventDefault()
    : "";
const Number2 = event =>
  !/^\d*(\.\d*)?$/.test(event.target.value + event.key)
    ? event.preventDefault()
    : "";

const DecimalNumber = event =>
  !/^[1-9]\d*\.?\d*$/i.test(String.fromCharCode(event.which))
    ? event.preventDefault()
    : "";

export default {
  alphabetic,
  email,
  aol,
  wholeNumber,
  Number,
  Number2,
  DecimalNumber,
  alphaNumeric,
  alphaNumericPlusMinus,
  alphaNumericPlusMinusNoSpace,
  alphaNumeric2,
  maxLength4,
};
