import React from "react";
import { Navigate } from "react-router-dom";

const Authmiddleware = props => {
  // if (!localStorage.getItem("User")) {
  //   return (
  //     <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
  //   );
  // }
  if (localStorage.getItem("User")) {
    if (window.location.pathname == "/") {
      return (
        <Navigate
          to={{ pathname: "/dashboard", state: { from: props.location } }}
        />
      );
    } else {
      return <React.Fragment>{props.children}</React.Fragment>;
    }
  } else if (localStorage.getItem("session_timeout")) {
    return <React.Fragment>{props.children}</React.Fragment>;
  } else {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }
};

export default Authmiddleware;
