import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
//Dashboard
import Dashboard from "./dashboard/reducer";

import crudTbCampaigns from "./Campaigns/reducer";
import crudTbCampaignCategories from "./CampaignsCategories/reducer";
import crudTbProducts from "./Products/reducer";
import crudTbPrize from "./Prize/reducer";
import crudTbDraws from "./Draws/reducer";
import crudTbWinners from "./Winners/reducer";
import crudTbCoupons from "./Coupons/reducer";
import crudTbPushNotifications from "./PushNotifications/reducer";
import crudTbMultiCurrency from "./MultiCurrency/reducer";
import crudTbShippingCharges from "./ShippingCharges/reducer";
import crudTbOrders from "./Orders/reducer";
import crudTbHomeBanner from "./HomeBanner/reducer";
import crudTbTickets from "./Tickets/reducer";
import crudTbUsers from "./Users/reducer";
import crudTbTermsAndConditions from "./TermsAndConditions/reducer";

/**
 * Branching reducers to use one reducer for many components
 */

function branchReducer(reducerFunction, reducerName) {
  return (state, action) => {
    const { branch } = action;
    const isInitializationCall = state === undefined;
    if (branch !== reducerName && !isInitializationCall) {
      return state;
    }
    return reducerFunction(state, action);
  };
}

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Dashboard,

  // Real Pages
  crudTbCampaigns: branchReducer(crudTbCampaigns, "crudTbCampaigns"),
  crudTbCampaignCategories: branchReducer(
    crudTbCampaignCategories,
    "crudTbCampaignCategories"
  ),
  crudTbProducts: branchReducer(crudTbProducts, "crudTbProducts"),
  crudTbPrize: branchReducer(crudTbPrize, "crudTbPrize"),
  crudTbDraws: branchReducer(crudTbDraws, "crudTbDraws"),
  crudTbWinners: branchReducer(crudTbWinners, "crudTbWinners"),
  crudTbCoupons: branchReducer(crudTbCoupons, "crudTbCoupons"),
  crudTbPushNotifications: branchReducer(
    crudTbPushNotifications,
    "crudTbPushNotifications"
  ),
  crudTbMultiCurrency: branchReducer(
    crudTbMultiCurrency,
    "crudTbMultiCurrency"
  ),
  crudTbShippingCharges: branchReducer(
    crudTbShippingCharges,
    "crudTbShippingCharges"
  ),
  crudTbOrders: branchReducer(crudTbOrders, "crudTbOrders"),
  crudTbHomeBanner: branchReducer(crudTbHomeBanner, "crudTbHomeBanner"),
  crudTbTickets: branchReducer(crudTbTickets, "crudTbTickets"),
  crudTbUsers: branchReducer(crudTbUsers, "crudTbUsers"),
  crudTbTermsAndConditions: branchReducer(
    crudTbTermsAndConditions,
    "crudTbTermsAndConditions"
  ),
});

export default rootReducer;
